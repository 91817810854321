import React, { useEffect, useState } from "react";
import AdvanceDataTable from "../UI/Table/AdvanceDataTable";
import { ADMIN_BILLING_SUMMARY } from "../UI/Table/ColumnConst";
import ReactDatePicker from "react-datepicker";
import AdminLayout from "../UI/Layout/AdminLayout";
import Title from "../UI/Text/Title";
import Spining from "../UI/Spining/Spining";
import { userloginDetails } from "../Utils/AdminAuth";
import Select from "react-select";
import Axios from "axios";
import { HOST_URL, NODE_HOST_URL } from "../Dashboard/api/Api";

const BillingSummary = () => {
  const lStorage = userloginDetails();
  const userId = lStorage.user.userId;
  const userToken = lStorage.token;
  const adminType = lStorage.user.isAdmin;
  const [alert, setAlert] = useState({
    status: false,
    bgColor: "",
    errorMessage: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [fromdate, setFromdate] = useState(new Date());
  const [todate, setTodate] = useState(new Date());
  const [allusers, setAllusers] = useState([{ value: "", label: "All Users" }]);
  const [seleteduser, setSeletedusers] = useState("");
  const [billData, setBillData] = useState([]);
  const customStyles = {
    control: (provided) => ({
      ...provided,
      cursor: "pointer",
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    option: (style) => ({
      ...style,
      fontSize: "12px",
    }),
  };
  useEffect(() => {
    usersList();
  }, []);
  const usersList = () => {
    setIsLoading(true);
    fetch(
      `${HOST_URL}/admin_user_list.php?user_id=${userId}&method=retrieve_whatsapp_user&type=${adminType}&token=${userToken}`
    ).then((response) => {
      response.json().then((result) => {
        if (result.success === true) {
          let data = result.data.filter((items) => {
            if (items.status === 1) {
              return items;
            }
          });

          data.forEach((item) => {
            let newuserData = { value: item.user_id, label: item.user_name };
            setAllusers((prevState) => [...prevState, newuserData]);
          });
        } else {
          setAlert({
            status: true,
            bgColor: "danger",
            errorMessage: result.message,
          });
        }
      });
    });
    setIsLoading(false);
  };
  const currentDate = (currentDate) => {
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };
  const userHandler = (e) => {
    setSeletedusers(e.value);
  };

  const fromdateHandler = (data) => {
    setFromdate(data);
  };
  const todateHandler = (data) => {
    setTodate(data);
  };
  const handleSearch = async () => {
    setIsLoading(true);
    let sDate = currentDate(fromdate);
    let eDate = currentDate(todate);
    try {
      const dataforsearch = {
        user_id: userId,
        token: userToken,
        method: "retrieve_wp_billing_summary",
        from_date: sDate,
        to_date: eDate,
        search_user_id: seleteduser,
      };
      const res = await Axios.post(
        `${NODE_HOST_URL}/admin/billing_summary`,
        dataforsearch
      );
      
      if (res.data.success === true) {
        setBillData(res.data.data);
      } else {
        setBillData([]);
        setAlert({
          status: true,
          bgColor: "alert alert-danger alert-dismissible",
          errorMessage: res.data.message,
        });
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  return (
    <div>
      <AdminLayout>
        <Title isButton={false}> Billing Summary </Title>
        {alert.status === true ? (
          <div className={alert.bgColor} role="alert">
            <button
              type="button"
              className="close"
              data-dismiss="alert"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
            <div className="">{alert.errorMessage}</div>
          </div>
        ) : null}
        <div className="row">
          <div className="">
            <div className="card">
              {isLoading && <Spining />}

              <div className="card-body">
                <div className="filter-option">
                  <h6 className="card-subtitle text-muted">Campaign Summary</h6>
                  <div className="row">
                    <div className="col-md-3">
                      <div className="input-group drop-down-icons mb-2 mr-sm-2">
                        <div className="input-group mb-2 mr-sm-2">
                          {/* <div className="input-group-text">User </div> */}
                          <Select
                            onChange={userHandler}
                            options={allusers}
                            isSearchable={true}
                            placeholder="All Users"
                            styles={customStyles}

                            // isClearable
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="input-group mb-2 mr-sm-2">
                        {/* <div className="input-group-text">
                          <FontAwesomeIcon icon={faCalendarAlt} />
                        </div> */}

                        <ReactDatePicker
                          className="form-control btn-block"
                          dateFormat="dd/MM/yyyy"
                          value={fromdate}
                          onChange={fromdateHandler}
                          selected={fromdate}
                          placeholderText="From Date"
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="input-group mb-2 mr-sm-2">
                        {/* <div className="input-group-text">
                          <FontAwesomeIcon icon={faCalendarAlt} />
                        </div> */}
                        <ReactDatePicker
                          className="form-control btn-block"
                          dateFormat="dd/MM/yyyy"
                          value={todate}
                          onChange={todateHandler}
                          selected={todate}
                          placeholderText="Date To"
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="input-group btn-block mb-2 mr-sm-2">
                        <button
                          onClick={handleSearch}
                          className="btn mt-1 btn-blue btn-sm  btn-block"
                        >
                          Search{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <AdvanceDataTable
                    tableName={""}
                    tableCell={ADMIN_BILLING_SUMMARY}
                    tableData={billData}
                    isLoading={isLoading}
                    isError={alert.errorMessage}
                    filterstatus={true}
                    pagination={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </AdminLayout>
    </div>
  );
};

export default BillingSummary;
