import React, { useEffect, useState } from "react";
import AdminLayout from "../UI/Layout/AdminLayout";
import { userloginDetails } from "../Utils/AdminAuth";
import Title from "../UI/Text/Title";

const QueueStatusWhatsapp = () => {
  const adminAuthDetails = userloginDetails();
  const [data, setdata] = useState({});

  useEffect(() => {
    dataList();
  }, []);

  const dataList = async () => {
    let data = {
      token: adminAuthDetails.token,
      user_id: adminAuthDetails.user.userId,
      method: "queue_status",
    };
    try {
      let response = await fetch(`https://napi.authkey.io/api/whatsapp_queue`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      let result = await response.json();
      if (result.success) {
        setdata(result.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <AdminLayout>
      <Title isButton={false}> Queue Status </Title>

      <div className="row">
        <div className="">
          <div className="card">
            <div className="card-body">
              <div className="container-fluid p-0">
                <div className="row mb-3 mb-xl-3">
                  <div className="col-auto d-none d-sm-block">
                    <h3 className="mt-2">
                      <strong>Bulk</strong>
                    </h3>
                  </div>
                </div>

                <div className="row">
                  <div className="col-xl-12  mt-3 col-xxl-5 d-flex">
                    <div className="w-100">
                      <div className="row">
                        <div className="col-sm-3">
                          <div className="card">
                            <div className="card-body">
                              <h5 className="card-title mb-4">Airtel Bulk</h5>
                              <h2 className="mt-1 mb-3">{data.airtel_bulk}</h2>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-3">
                          <div className="card">
                            <div className="card-body">
                              <h5 className="card-title mb-4">Datagen Bulk</h5>
                              <h2 className="mt-1 mb-3">{data.datagen_bulk}</h2>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-3">
                          <div className="card">
                            <div className="card-body">
                              <h5 className="card-title mb-4">Pinacle Bulk</h5>
                              <h2 className="mt-1 mb-3">{data.pinacle_bulk}</h2>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-3">
                          <div className="card">
                            <div className="card-body">
                              <h5 className="card-title mb-4">WP Bulk</h5>
                              <h2 className="mt-1 mb-3">{data.wp_bulk}</h2>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="card">
          <div className="card-body">
            <div className="container-fluid p-0">
              <div className="row mb-3 mb-xl-3">
                <div className="col-auto d-none d-sm-block">
                  <h3 className="mt-2">
                    <strong>Incoming</strong>
                  </h3>
                </div>
              </div>

              <div className="row">
                <div className="col-xl-12  mt-3 col-xxl-5 d-flex">
                  <div className="w-100">
                    <div className="row">
                      <div className="col-sm-3">
                        <div className="card">
                          <div className="card-body">
                            <h5 className="card-title mb-4">
                              Airtel Incomming
                            </h5>
                            <h2 className="mt-1 mb-3">
                              {data.airtel_incomming}
                            </h2>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="card">
                          <div className="card-body">
                            <h5 className="card-title mb-4">FB Incomming </h5>
                            <h2 className="mt-1 mb-3">{data.fb_incomming}</h2>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="card">
                          <div className="card-body">
                            <h5 className="card-title mb-4">
                              Netcore Incomming
                            </h5>
                            <h2 className="mt-1 mb-3">
                              {data.netcore_incomming}
                            </h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="card">
          <div className="card-body">
            <div className="container-fluid p-0">
              <div className="row mb-3 mb-xl-3">
                <div className="col-auto d-none d-sm-block">
                  <h3 className="mt-2">
                    <strong>Request</strong>
                  </h3>
                </div>
              </div>

              <div className="row">
                <div className="col-xl-12  mt-3 col-xxl-5 d-flex">
                  <div className="w-100">
                    <div className="row">
                      <div className="col-sm-3">
                        <div className="card">
                          <div className="card-body">
                            <h5 className="card-title mb-4">Request Queue</h5>
                            <h2 className="mt-1 mb-3">
                              {data.request_queue}
                            </h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default QueueStatusWhatsapp;
