import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { NODE_HOST_URL } from '../api/Api';
import DashboardLayout from '../../UI/Layout/DashboardLayout';
import { Alert } from 'bootstrap';
import AdvanceDataTable from '../../UI/Table/AdvanceDataTable';
import { CAMPAIGN_REPORT_CTA_TABLE } from '../../UI/Table/ColumnConst';
import Title from '../../UI/Text/Title';

const CtaCampaignReport = () => {
    const lStorage = JSON.parse(localStorage.getItem("login"));
    const user_id = lStorage.user.userId;
    const token = lStorage.token;

    const {id} = useParams();
    const [ctaCampaignData , setCtaCampaignData] = useState([])

    useEffect(()=>{
        getCtaCampaignList();
    },[])

    const getCtaCampaignList = async() =>{
        let data = {
            user_id: user_id ,
            token: token,
            method:"retrieve_cta_camp",
            camp_id: id
        }
        try {
            let response = await fetch(`${NODE_HOST_URL}/btn_click_retrieve`,{
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });
           let result = await response.json();
           if(result.sucess){
            // console.log(result);
            setCtaCampaignData(result.data)
           }
        } catch (error) {
            console.log(error)
        }
    }
    
  return (
    <div>
     <DashboardLayout>
        <main className="content">
          <div className="container-fluid p-0">
          <div className='row'>
              <div className='col-6'>
                <Title
                  isButton={false}
                  btnName={`Create New Contact List `}
                  actionURL={`/dashboard/create-contact-list`}
                >
                  CTA Campaign Reports / <span style={{ color: "#3f3966" }}>{id}</span>
                </Title>
              </div>
              {ctaCampaignData.length > 0 &&
              <div className='col-6'>
                <span className='btn btn-primary float-right mb-3' style={{ fontSize: "12px", padding: "10px 10px 10px 10px" }}>
                  <a
                    href={`${NODE_HOST_URL}/clickreport_download_camp_csv?user_id=${user_id}&token=${token}&submit_via=pannel&camp_id=${id}&method=clickreport_download_camp_csv`}
                    download="campaign_report.csv"
                    target="_blank" 
                    rel="noopener noreferrer" 
                    style={{ textDecoration: 'none', color: "white" }} 
                  >
                    Download Csv
                  </a>
                  
                </span>
              </div>}
            </div>

            {/* {this.state.isError && (
              <Alert type={this.state.messageType}>
                {this.state.errorMessage}
              </Alert>
            )} */}

            {/* <div className="card"> */}

              {/* <h3 className="pl-3 pt-3"><span className="text-success">CTA Campaign Reports : </span></h3>
              <hr className="mt-0" /> */}
              <AdvanceDataTable
                tableName={""}
                tableCell={CAMPAIGN_REPORT_CTA_TABLE}
                tableData={ctaCampaignData}
                // isLoading={this.state.setIsLoading}
                // isError={this.state.ErrorResponse}
              />             
            {/* </div> */}
          </div>
        </main>
      </DashboardLayout>
    </div>
  )
}

export default CtaCampaignReport
