import React, { useEffect, useState } from "react";
import AdminLayout from "../../UI/Layout/AdminLayout";
import Spining from "../../UI/Spining/Spining";
import Select from "react-select";
import { HOST_URL, NODE_HOST_URL } from "../../Dashboard/api/Api";
import { userloginDetails } from "../../Utils/AdminAuth";
import Axios from "../../../axios/Axios";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
const RcsSetting = () => {
  const lStorage = userloginDetails();
  const userId = lStorage.user.userId;
  const userToken = lStorage.token;
  const adminType = lStorage.user.isAdmin;
  const [error, setError] = useState({
    bgColor: "alert alert-success alert-dismissible",
    errorMessage: "",
    successMessage: false,
  });
  const [loading, setLoading] = useState(false);
  const [allUsers, setAllusers] = useState([]);
  const [selecteduser, setSelectedusers] = useState("");
  const [botId, setBotId] = useState("");
  const [senderId, setSenderId] = useState("");
  const [type, setType] = useState("");
  const [description, setDescription] = useState("");
  const [route, setRoute] = useState("");
  const [status, setStaus] = useState("");
  const customStyles = {
    control: (provided) => ({
      ...provided,
      cursor: "pointer",
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    option: (style) => ({
      ...style,
      fontSize: "12px",
    }),
  };
  useEffect(() => {
    usersList();
  }, []);

  const usersList = async () => {
    setLoading(true);
    try {
      const { data } = await Axios.get(
        `${HOST_URL}/admin_user_list.php?user_id=${userId}&method=retrieve_all_user&type=${adminType}&token=${userToken}`
      );

      if (data.success) {
        // console.log(result.data)
        const formattedUsers = data.data.map((user) => ({
          value: user.id,
          label: user.email,
        }));

        setAllusers(formattedUsers);
      }
    } catch (error) {
      console.log(error.message);
      setError({
        bgColor: "alert alert-danger alert-dismissible",
        errorMessage: error.message,
        successMessage: true,
      });
    }

    setLoading(false);
  };
  const handleSubmit = async(e) => {
    e.preventDefault();
    
    if (!selecteduser) {
      setError({
        bgColor: "alert alert-danger alert-dismissible",
        errorMessage: "select user",
        successMessage: true,
      });
      return;
    }
    
    try {
      setLoading(true);
      const dataForRcsSetting = {
        user_id:userId,
        token:userToken,
        method:"add_setting",
        username:selecteduser.label,
        rcs_user_id:selecteduser.value,
        type,
        sender_id:senderId,
        bot_id:botId,
        route,
        status,
        description
      }
      const {data} = await Axios.post(`${NODE_HOST_URL}/admin/rcs_setting`,dataForRcsSetting);
      if(data.success===true){

        setError({
          bgColor: "alert alert-success alert-dismissible",
          errorMessage: data.message,
          successMessage: true,
        });
        setSelectedusers("");
        setBotId("");
        senderId("");
        setType("");
        setDescription("");
        setRoute("");
        setStaus("");
      }
      
    } catch (error) {
      setError({
        bgColor: "alert alert-danger alert-dismissible",
        errorMessage: error.message,
        successMessage: true,
      });
    }
    setLoading(false);
  };
  return (
    <>
      {loading && <Spining />}
      <AdminLayout>
        {error.successMessage ? (
          <div className={error.bgColor} role="alert">
            <button
              type="button"
              className="close"
              data-dismiss="alert"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
            <div className="">{error.errorMessage}</div>
          </div>
        ) : null}
        <div className="row">
          <div className="col-3">
            <h3>
              <strong>RCS Setting </strong>
            </h3>
          </div>
          <div className="col-9 mb-2">
            <Link
              type="button"
              style={{
                float: "right",
                fontSize: "12px",
                padding: "10px 20px 10px 20px",
              }}
              to={"/admin/rcs/rcs-setting-list"}
              className="btn btn-success"
            >
              Back
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-12 col-lg-12 col-xxl-12 d-flex">
                  <div className=" flex-fill">
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="mb-3 col-md-6">
                          <label
                            className="form-label"
                            htmlFor="inputFirstName"
                          >
                            User<span className="text-danger">*</span>
                          </label>
                          <Select
                            onChange={(selectedOption) =>
                              setSelectedusers(selectedOption)
                            }
                            options={allUsers}
                            value={selecteduser}
                            isSearchable={true}
                            placeholder="All Users"
                            isClearable={true}
                            styles={customStyles}
                            isLoading={loading}
                          />
                        </div>
                        <div className="mb-3 col-md-6">
                          <label className="form-label" htmlFor="inputEmail4">
                            Bot Id<span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            value={botId}
                            onChange={(e) => setBotId(e.target.value)}
                            className="form-control"
                            placeholder="Enter Bot Id"
                            required
                          />
                        </div>
                        <div className="mb-3 col-md-6">
                          <label
                            className="form-label"
                            htmlFor="inputFirstName"
                          >
                            Sender Id<span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            value={senderId}
                            onChange={(e) => setSenderId(e.target.value)}
                            className="form-control"
                            placeholder="Enter Sender Id"
                            required
                          />
                        </div>
                        <div className="mb-3 col-md-6">
                          <div className="form-group">
                            <label htmlFor="select type">
                              Type<span className="text-danger">*</span>
                            </label>
                            <select
                              className="form-control"
                              id="select type"
                              value={type}
                              onChange={(e) => setType(e.target.value)}
                              required
                            >
                              <option value={""}>Select type</option>
                              <option value={"promotional"}>Promotional</option>
                              <option value={"transactional"}>
                                Transactional
                              </option>
                              <option value={"otp"}>Otp</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="mb-3 col-md-12">
                          <div className="form-group">
                            <label htmlFor="exampleFormControlTextarea1">
                              Description
                            </label>
                            <textarea
                              value={description}
                              onChange={(e) => setDescription(e.target.value)}
                              className="form-control"
                              id="exampleFormControlTextarea1"
                              rows="3"
                              placeholder="Description"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="mb-3 col-md-6">
                          <div className="form-group">
                            <label htmlFor="select route">
                              Route<span className="text-danger">*</span>
                            </label>
                            <select
                              value={route}
                              onChange={(e) => setRoute(e.target.value)}
                              className="form-control"
                              id="select route"
                              required
                            >
                              <option value={""}>Select route</option>
                              <option value={"karix"}>karix</option>
                            </select>
                          </div>
                        </div>
                        <div className="mb-3 col-md-6">
                          <div className="form-group">
                            <label htmlFor="select route">
                              Status<span className="text-danger">*</span>
                            </label>
                            <select
                              className="form-control"
                              value={status}
                              onChange={(e) => setStaus(e.target.value)}
                              id="select status"
                              required
                            >
                              <option value={""}>Select status</option>
                              <option value={"1"}>Active</option>
                              <option value={"0"}>Inactive</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="float-right">
                        <input
                          type="submit"
                          value="Submit"
                          className="btn btn-sm  btn-primary"
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AdminLayout>
    </>
  );
};

export default RcsSetting;
