import React, { useEffect, useState } from "react";
import AdvanceDataTable from "../../UI/Table/AdvanceDataTable";
import AdminLayout from "../../UI/Layout/AdminLayout";
import Spining from "../../UI/Spining/Spining";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { ADMIN_RCS_SETTING_LIST } from "../../UI/Table/ColumnConst";
import Axios from "../../../axios/Axios";
import { NODE_HOST_URL } from "../../Dashboard/api/Api";
import { userloginDetails } from "../../Utils/AdminAuth";

const RcsSettingList = () => {
  const lStorage = userloginDetails();
  const userId = lStorage.user.userId;
  const userToken = lStorage.token;
  const [loading, setLoading] = useState(false);
  const [listData, setListData] = useState([]);
  useEffect(() => {
    fetchRcsSettingList();
  }, []);
  const fetchRcsSettingList = async () => {
    setLoading(true);
    try {
      const dataForList = {
        user_id: userId,
        token: userToken,
        method: "retrieve_setting",
      };
      const { data } = await Axios.post(
        `${NODE_HOST_URL}/admin/rcs_setting`,
        dataForList
      );
      if (data.success === true) {
        setListData(data.data);
      }
    } catch (error) {
      console.log(error.message);
    }
    setLoading(false)
  };
  return (
    <>
      {loading && <Spining />}
      <AdminLayout>
        <div className="row">
          <div className="col-3">
            <h6>RCS Setting List</h6>
          </div>
          <div className="col-9 mb-2">
            <Link
              type="button"
              style={{
                float: "right",
                fontSize: "12px",
                padding: "10px 10px 10px 10px",
              }}
              to={"/admin/rcs/rcs-setting-add"}
              className="btn btn-success"
            >
              Add new
            </Link>
          </div>
        </div>

        <AdvanceDataTable
          tableCell={ADMIN_RCS_SETTING_LIST}
          tableData={listData}
          isLoading={loading}
        />
      </AdminLayout>
    </>
  );
};

export default RcsSettingList;
