import React, { Component } from "react";
import DashboardHeader from "../../Header/DashboardHeader";
import DashboardFooter from "../../Footer/DashboardFooter";
import DashboardLeftMenu from "../../Dashboard/DashboardLeftMenu";
import rcsVudeo from "../../Dashboard/assets/video/rcsvideo.mp4";
import { HOST_URL } from "../api/Api";
class RcsTemplate extends Component {
  constructor() {
    const lStorage = JSON.parse(localStorage.getItem("login"));
    const userId = lStorage.user.userId;
    const userToken = lStorage.token;

    super();
    this.state = {
      user_id: userId,
      token: userToken,
      email: "",
      department: "",
      projectList: "",
      messageBody: "",
      parent_id: "",
      errorMessage: "",
      successMessage: false,
      bgColor: "",
    };
  }

  componentDidMount() {
    fetch(
      `${HOST_URL}/project_template.php?user_id=${this.state.user_id}&method=retrieve&token=${this.state.token}`
    ).then((response) => {
      response.json().then((result) => {
        // console.log(result);
        if (result.success === true) {
          this.setState({ projectList: result });
        }
      });
    });
  }

  render() {
    return (
      <div className="wrapper">
        <DashboardLeftMenu />
        <div className="main">
          <DashboardHeader />
          <main className="content">
            <div className="container-fluid p-0">
              <div className="row justify-content-center">
                <div className="col-md-8">
                  <div className="card">
                    <div className="card-body">
                      {this.state.successMessage ? (
                        <div className={this.state.successBg} role="alert">
                          <button
                            type="button"
                            className="close"
                            data-dismiss="alert"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">×</span>
                          </button>
                          {this.state.errorMessage}
                        </div>
                      ) : null}

                      <form id="formSmstemplate">
                        <div className="row justify-content-center">
                          <div className="mb-3 col-md-12">
                            <div className="text-left">
                              <h3 className="font-weight-bold mb-4">
                                {" "}
                                RCS Template{" "}
                              </h3>
                            </div>
                            <div className="form-row">
                              <div className="form-group col-md-12">
                                <input
                                  type="text"
                                  onChange={(event) => {
                                    this.setState({
                                      template_name: event.target.value,
                                    });
                                  }}
                                  className="form-control"
                                  id="inputEmail4"
                                  placeholder="Enter RCS Template Name"
                                />
                              </div>
                            </div>

                            <div
                              style={{
                                background: "#f0f8ff",
                                border: "2px dotted #69b6f9",
                              }}
                              className=""
                            >
                              <div className="col-md-12">
                                <label className="col-form-label">Image</label>
                                <input className="form-control" type="file" />
                                <small>
                                  {" "}
                                  Image Dimension : 1440 px * 448 px and Formate
                                  .jpg,.png
                                </small>
                              </div>
                            </div>

                            <div className="form-row mt-4">
                              <div className="form-group col-md-12">
                                <input
                                  type="text"
                                  onChange={(event) => {
                                    this.setState({
                                      template_id: event.target.value,
                                    });
                                  }}
                                  className="form-control"
                                  id="inputEmail4"
                                  placeholder="Enter Website Link"
                                />
                              </div>
                            </div>

                            <div className="form-group mt-2">
                              <textarea
                                onChange={(event) => {
                                  this.setState({
                                    content: event.target.value,
                                  });
                                  this.getVaribale();
                                }}
                                placeholder="RCS Template Message Content"
                                className="form-control"
                                rows="5"
                              ></textarea>
                            </div>

                            <button
                              type="button"
                              onClick={() => {
                                this.CreateSMSTemplate();
                              }}
                              className="btn btn-block mt-3 btn-primary"
                            >
                              Create Template
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="card">
                    <div className="">
                      <div className="collapse show" id="collapseExample">
                        <div className="links-set">
                          <video
                            style={{ width: 100 + "%" }}
                            autoPlay
                            muted
                            loop
                          >
                            <source src={rcsVudeo} />
                          </video>
                          {/* <img src={imagercs} className="img-fluid" /> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
          <DashboardFooter />
        </div>
      </div>
    );
  }
}

export default RcsTemplate;
