import React, { useEffect, useState } from 'react'
import EmptyImg from "../../Dashboard/assets/img/create-project.png";
import DashboardLeftMenu from '../DashboardLeftMenu';
import DashboardHeader from '../../Header/DashboardHeader';
import Dialog from 'react-dialog';
import { HOST_URL, NODE_HOST_URL } from '../api/Api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

const FixedUrl = () => {
    const lStorage = JSON.parse(localStorage.getItem("login"));
    const userId = lStorage.user.userId;
    const userToken = lStorage.token;
    const userName = lStorage.user.username;
    const date = new Date();

    const [urlList, setUrlList] = useState([]);
    const [brandNumberData, setBrandNumberData] = useState('')
    const [id, setId] = useState('')
    const [senderId, setSenderId] = useState('')
    const [dataItem, setDataItem] = useState({
        channel: "whatsapp",
        brandnumber: '',
        main_url: '',
        status: '',
        sender_id: ''
    });
    const [error, setError] = useState({
        errorStatus: false,
        errorMessage: '',
        bgColor: ''
    })

    useEffect(() => {
        getBrandNumber();
        getUrlList();
        getSenderId();
    }, [])

    const getBrandNumber = async () => {
        try {
            let response = await fetch(`${HOST_URL}/wp_profile.php?user_id=${userId}&method=retrieve&token=${userToken}`);
            let result = await response.json();
            if (result.success) {
                setBrandNumberData(result.data[0].brand_number)
            }
        } catch (error) {
            console.log(error);
        }
    }

    const getSenderId = async () => {
        try {
            let response = await fetch(`${HOST_URL}/sms_template.php?user_id=${userId}&method=retrieve&id=null&token=${userToken}`);
            let result = await response.json();
            if (result.success) {
                setSenderId(result.data);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const onChange = (e) => {
        setDataItem({ ...dataItem, [e.target.name]: e.target.value });
        setError({ errorStatus: false });
    }

    const isValid = () => {
        if (dataItem.channel === undefined || dataItem.channel === '') {
            setError({
                errorStatus: true,
                errorMessage: 'Please Select Channel',
                bgColor: 'alert alert-danger'
            });
            return false;
        }

        if (dataItem.channel === "whatsapp" && !id) {
            if (dataItem.brandnumber === undefined || dataItem.brandnumber === '') {
                setError({
                    errorStatus: true,
                    errorMessage: 'Please Select Brand Number',
                    bgColor: 'alert alert-danger'
                });
                return false;
            }
        }

        if ((dataItem.channel === "sms" || dataItem.channel === "rcs") && !id) {
            if (dataItem.sender_id === undefined || dataItem.sender_id === '') {
                setError({
                    errorStatus: true,
                    errorMessage: 'Please Select Sender Id',
                    bgColor: 'alert alert-danger'
                });
                return false;
            }
        }

        if (dataItem.main_url === undefined || dataItem.main_url === '') {
            setError({
                errorStatus: true,
                errorMessage: 'Please Enter Url',
                bgColor: 'alert alert-danger'
            });
            return false;
        }

        return true;
    }


    const currentDate = (currentDate) => {
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, "0");
        const day = String(currentDate.getDate()).padStart(2, "0");

        const hours = String(currentDate.getHours()).padStart(2, "0");
        const minutes = String(currentDate.getMinutes()).padStart(2, "0");
        const seconds = String(currentDate.getSeconds()).padStart(2, "0");

        const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        return formattedDate;
    };


    const CreateUrl = async () => {
        if (isValid()) {
            let data = {
                user_id: userId,
                token: userToken,
                username: userName,
                method: "create",
                main_url: dataItem.main_url,
                created_date: currentDate(date),
                channel: dataItem.channel,
                status: dataItem.status,
                brandnumber: dataItem.channel === "whatsapp" ? dataItem.brandnumber : dataItem.sender_id
            }
            try {
                let response = await fetch(`${NODE_HOST_URL}/fixed_url`, {
                    method: "Post",
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                    },
                    body: JSON.stringify(data)
                })
                let result = await response.json();
                if (result.success) {
                    setError({
                        errorStatus: true,
                        errorMessage: result.message,
                        bgColor: 'alert alert-success'
                    });
                    getUrlList();
                    setDataItem({
                        channel: "whatsapp",
                        brandnumber: '',
                        main_url: '',
                        status: ''
                    });
                    setTimeout(() => {
                        setError({ errorStatus: false });
                    }, 2000);
                }
                else {
                    setError({
                        errorStatus: true,
                        errorMessage: result.message,
                        bgColor: 'alert alert-danger'
                    });
                }
            } catch (error) {
                console.log(error);
            }
        }
    }

    const getUrlList = async () => {
        let data = {
            user_id: userId,
            token: userToken,
            method: "retrieve",
        }
        try {
            let response = await fetch(`${NODE_HOST_URL}/fixed_url`, {
                method: "Post",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                },
                body: JSON.stringify(data)
            })
            let result = await response.json();
            if (result.success) {
                setUrlList(result.data);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const EditUrlID = async (id) => {
        let data = {
            user_id: userId,
            token: userToken,
            method: "retrieve_id",
            _id: id
        }
        try {
            let response = await fetch(`${NODE_HOST_URL}/fixed_url`, {
                method: "Post",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                },
                body: JSON.stringify(data)
            })
            let result = await response.json();
            if (result.success) {
                let ret_data = result.data
                setId(ret_data._id)
                setDataItem({
                    channel: ret_data.channel,
                    brandnumber: '',
                    main_url: ret_data.main_url,
                    status: ret_data.status,
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    const Update = async () => {
        if (isValid()) {
            let data = {
                user_id: userId,
                token: userToken,
                method: "update",
                main_url: dataItem.main_url,
                created_date: currentDate(date),
                channel: dataItem.channel,
                status: dataItem.status,
                brandnumber: dataItem.channel === "whatsapp" ? dataItem.brandnumber : dataItem.sender_id,
                _id: id
            }
            try {
                let response = await fetch(`${NODE_HOST_URL}/fixed_url`, {
                    method: "Post",
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                    },
                    body: JSON.stringify(data)
                })
                let result = await response.json();
                if (result.success) {
                    setError({
                        errorStatus: true,
                        errorMessage: result.message,
                        bgColor: 'alert alert-success'
                    });
                    getUrlList();
                    setDataItem({
                        channel: "whatsapp",
                        brandnumber: '',
                        main_url: '',
                        status: ''
                    });
                    setId('')
                    setTimeout(() => {
                        setError({ errorStatus: false });
                    }, 2000);
                }
                else {
                    setError({
                        errorStatus: true,
                        errorMessage: result.message,
                        bgColor: 'alert alert-danger'
                    });
                }
            } catch (error) {
                console.log(error);
            }
        }
    }

    const Delete = async (id) => {
        let data = {
            user_id: userId,
            token: userToken,
            method: "delete",
            _id: id,
        }
        try {
            let response = await fetch(`${NODE_HOST_URL}/fixed_url`, {
                method: "Post",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                },
                body: JSON.stringify(data)
            })
            let result = await response.json();
            if (result.success) {
                setError({
                    errorStatus: true,
                    errorMessage: result.message,
                    bgColor: 'alert alert-success'
                });
                let newList = urlList.filter(x => x._id !== id)
                setUrlList(newList);

                setTimeout(() => {
                    setError({ errorStatus: false });
                }, 2000);
            }
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div className="wrapper">
            <DashboardLeftMenu />
            <div className="main">
                <DashboardHeader />
                <main className="content">
                    <div className="card">
                        <div className="card-body">
                            <div className="container-fluid p-0">
                                <div className="">
                                    <div className="row">
                                        <div className="col-md-12">
                                            {
                                                error.errorStatus ?
                                                    <div className={error.bgColor} role="alert">
                                                        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                                            <span aria-hidden="true">×</span>
                                                        </button>
                                                        <div className="">
                                                            {error.errorMessage}
                                                        </div>
                                                    </div>
                                                    :
                                                    null
                                            }
                                            <h5 className="card-title">Fixed Url</h5>
                                            <div className="">
                                                <form className="form-row" id="EntityFormidFrom">
                                                    <div className={id ? "mb-3 col-md-6" : "mb-3 col-md-4"}>
                                                        <select class="form-control" disabled={id ? true : false} name="channel" value={dataItem.channel} onChange={onChange}  >
                                                            <option value="">Select Channel</option>
                                                            <option value="whatsapp">Whatsapp</option>
                                                            <option value="sms">Sms</option>
                                                            <option value="rcs">Rcs</option>
                                                        </select>
                                                    </div>

                                                    {dataItem.channel === "whatsapp" && !id ?
                                                        <div className="mb-3 col-md-4">
                                                            <select class="form-control" name="brandnumber" value={dataItem.brandnumber} onChange={onChange} >
                                                                <option value=""> Select Brand Number</option>
                                                                <option value={brandNumberData}>{brandNumberData}</option>
                                                            </select>
                                                        </div> : null}

                                                    {(dataItem.channel === "sms" || dataItem.channel === "rcs") && !id ?
                                                        <div className="mb-3 col-md-4">
                                                            <select class="form-control" name="sender_id" value={dataItem.sender_id} onChange={onChange} >
                                                                <option value=""> Select Sender Id</option>
                                                                {senderId?.map((x) => (
                                                                    <option value={x?.senderid}>{x?.senderid}</option>
                                                                ))}
                                                            </select>
                                                        </div> : null}

                                                    <div className={id ? "mb-3 col-md-6" : "mb-3 col-md-4"}>
                                                        <select class="form-control" name="status" value={dataItem.status} onChange={onChange} >
                                                            <option value=""> Select Status</option>
                                                            <option value="1">Active</option>
                                                            <option value="0">Inactive</option>
                                                        </select>
                                                    </div>
                                                </form>

                                                <form className="form-row" id="EntityFormidFrom">
                                                    <div className="mb-3  col-md-12">
                                                        <input type="text" name='main_url' value={dataItem.main_url} onChange={onChange} placeholder="Enter URL" className="form-control" />
                                                    </div>
                                                </form>

                                                <form className="form-row" id="EntityFormidFrom">
                                                    <div className="col-md-12 d-flex justify-content-center">
                                                        {id
                                                            ? <button type="button" onClick={() => Update()} className="btn btn-sm btn-success">Update</button>
                                                            : <button type="button" onClick={() => CreateUrl()} className="btn btn-sm btn-success">Submit</button>
                                                        }
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="">
                                        <div className="">
                                            <div className="p-2">
                                                <div className="">
                                                    <div className=" flex-fill">
                                                        {urlList ?
                                                            <div className="table-responsive">
                                                                <table className="table table-striped table-sm">
                                                                    <thead>
                                                                        <tr>
                                                                            <th className=" d-xl-table-cell"> Action </th>
                                                                            <th className=" d-md-table-cell">Channel</th>
                                                                            <th className=" d-md-table-cell">Status</th>
                                                                            <th className=" d-md-table-cell">Short Url</th>
                                                                            <th className=" d-md-table-cell">Main Url</th>
                                                                            <th className=" d-xl-table-cell">Created Date / Time </th>

                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            urlList.map((item, i) =>
                                                                                <tr key={i}>
                                                                                    <td>
                                                                                        <span onClick={() => EditUrlID(item._id, item.channel, item.status, item.main_url)} className="badge bg-primary mr-1"> <FontAwesomeIcon icon={faEdit} /> </span>
                                                                                        <span onClick={() => Delete(item._id)} className="badge bg-danger">
                                                                                            <FontAwesomeIcon icon={faTrash} />
                                                                                        </span>
                                                                                    </td>
                                                                                    <td className=" d-xl-table-cell">{item.channel}</td>
                                                                                    <td className=" d-xl-table-cell">{item.status === 0 ? <span style={{ color: "red" }}>Inactive</span> : <span style={{ color: "green" }}>Active</span>}</td>
                                                                                    <td className=" d-xl-table-cell">{item.url}</td>
                                                                                    <td className=" d-xl-table-cell">{item.main_url}</td>
                                                                                    <td className=" d-md-table-cell">{item.created_date}</td>
                                                                                </tr>
                                                                            )}
                                                                    </tbody>
                                                                </table> </div> :
                                                            <div className="text-center">
                                                                <div className="card-body">
                                                                    <img alt="Empty Data" className="project-empty-img" src={EmptyImg} />
                                                                    <p>No Entity ID Yet</p>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    )
}

export default FixedUrl
