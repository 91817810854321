import React, { Component } from 'react';
import AdminHeader from '../Header/AdminHeader';
import AdminFooter from '../Footer/AdminFooter';
import AdminLeftMenu from '../Navbar/AdminLeftMenu';
import { userloginDetails } from '../Utils/AdminAuth';
import { HOST_URL } from '../Dashboard/api/Api';

class AddNewClient extends Component {

    constructor() {

        const lStorage = userloginDetails();
        const userId = lStorage.user.userId;
        const userToken = lStorage.token;

        super();

        this.state = {
            user_id: userId,
            token: userToken,
            email: '',
            mobile: '',
            fullname: '',
            password: '',
            successMessage: false,
            errorMessage: '',
            bgColor: '',
        }
    }


    AddnewClients() {
        fetch(`${HOST_URL}/admin_user_list.php?user_id=${this.state.user_id}&name=${this.state.fullname}&email=${this.state.email}&mobile=${this.state.mobile}&password=${this.state.password}&method=adduser&token=${this.state.token}`).then((response) => {
            response.json().then((result) => {
                if (result.success === true) {
                    this.setState({
                        successMessage: true,
                        bgColor: "alert alert-success alert-dismissible",
                        errorMessage: result.message
                    })
                } else {
                    this.setState({
                        successMessage: true,
                        bgColor: "alert alert-danger alert-dismissible",
                        errorMessage: result.message
                    })
                }
            })
        })
    }



    render() {
        //console.log(this.state)
        return (
            <div className="wrapper">
                <AdminLeftMenu />
                <div className="main">
                    <AdminHeader />
                    <main className="content">
                        <div className="container-fluid p-0">
                            <div className="row mb-2 mb-xl-3">
                                <div className="col-auto d-none d-sm-block">
                                    <h3><strong>Add New </strong> Client </h3>
                                </div>

                                {
                                    this.state.successMessage ?
                                        <div className={this.state.bgColor} role="alert">
                                            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                                <span aria-hidden="true">×</span>
                                            </button>
                                            <div className="">
                                                {this.state.errorMessage}
                                            </div>
                                        </div>
                                        :
                                        null
                                }

                            </div>
                            <div className="row">
                                <div className="card">


                                    <div className="card-body">


                                        <div className="row">
                                            <div className="col-12 col-lg-12 col-xxl-12 d-flex">
                                                <div className=" flex-fill">
                                                    <form>
                                                        <div className="row">
                                                            <div className="mb-3 col-md-6">
                                                                <label className="form-label" htmlFor="inputFirstName">Full Name</label>
                                                                <input type="text" onChange={(event) => this.setState({ fullname: event.target.value })} className="form-control" placeholder="Enter Full Name" />
                                                            </div>
                                                            <div className="mb-3 col-md-6">
                                                                <label className="form-label" htmlFor="inputEmail4">Email</label>
                                                                <input type="email" onChange={(event) => this.setState({ email: event.target.value })} className="form-control" placeholder="Email" />
                                                            </div>
                                                            <div className="mb-3 col-md-6">
                                                                <label className="form-label" htmlFor="inputFirstName">Enter Mobile Number</label>
                                                                <input type="text" onChange={(event) => this.setState({ mobile: event.target.value })} className="form-control" placeholder="Enter 10 Digit Mobile Number" />
                                                            </div>
                                                            <div className="mb-3 col-md-6">
                                                                <label className="form-label" htmlFor="inputFirstName">Enter Password </label>
                                                                <input type="text" onChange={(event) => this.setState({ password: event.target.value })} className="form-control" placeholder="Enter Password" />
                                                            </div>
                                                        </div>




                                                        <div className="float-right">
                                                            <input onClick={() => this.AddnewClients()} type="button" value="Add Client " className="btn btn-sm  btn-primary" />
                                                        </div>


                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                    <AdminFooter />
                </div>
            </div>
        );
    }
}

export default AddNewClient;