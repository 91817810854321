import React, { Component } from "react";
import DashboardHeader from "../../Header/DashboardHeader";
import DashboardFooter from "../../Footer/DashboardFooter";
import DashboardLeftMenu from "../../Dashboard/DashboardLeftMenu";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBackward, faEdit } from "@fortawesome/free-solid-svg-icons";
import { HOST_URL, WhstappAccountList } from "../api/Api";
import flags from "../../Dashboard/assets/img/flags.png";
import ReactTelInput from "react-telephone-input";
import "../assets/css/whatsapp.css";
import metalogo from "../assets/img/What-is-Meta.png";
import arrowLeftRight from "../assets/img/LeftRightArrow (1).png";
import authkeylogonew from "../assets/img/authkeylogonew.png";
class WhatsappEnabale extends Component {
  constructor() {
    const lStorage = JSON.parse(localStorage.getItem("login"));
    const userId = lStorage.user.userId;
    const userToken = lStorage.token;
    const user_name = lStorage.user.username;
    super();
    this.state = {
      login_user: lStorage,
      user_id: userId,
      token: userToken,
      username: user_name,
      comp_name: "",
      brand_number: "",
      number_not_active: "",
      address: "",
      description: "",
      category: "",
      account_status: "",
      image_url: "",
      live_image_url: "",
      fb_businness_id: "",
      business_website: "",
      displayname: "",
      email: "",
      whatsapp_no_type: "",
      two_factor_authentication: "",
      profile_status: "",
      successMessage: false,
      errorMessage: "",
      bgColor: "",
      country_code: "91",
      country_name: "in",
      waba_id: "",
      aboutus: "",
      uid: "",
      utoken: "",
      uname: "",
      code: "",
      uid: "",
      token: "",
      uname: "",
      code: lStorage,
      new_waba_id: "",
      new_pn_id: "",
      enableFacebook: false,
      manageProfile: true,
      manageLogo: false,
      withFacebook: false,
      chooseFbOption: "",
      step: 1,
      whatsappNumber: [],
      isopenPopup: false,
      phoneNoId: "",
      waId: "",
      counter: 1,
      accessTokenFlag: 0,
      getAccessToken: "",
    };
    this.fileChangedHandler = this.fileChangedHandler.bind(this);
    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.whatsAppUses = this.whatsAppUses.bind(this);
    this.appSecret = "5597c677518a437ef27d7bdb026e3878";
    this.getTokenUrl = `https://graph.facebook.com/v19.0/oauth/access_token?client_id=675419764769671&client_secret=${this.appSecret}&code=`;
    this.laravelAppUrl = "https://console.authkey.io/api/pinnacel_data.php";
  }

  // componentDidMount() {
  //   if (this.props.match.params.id !== "") {
  //     this.editWPTemplate();
  //     // var form = document.getElementById("formViewOnly");
  //     // var elements = form.elements;
  //     // for (var i = 0, len = elements.length; i < len; ++i) {
  //     //    elements[i].disabled = true;
  //     // }
  //   } else {
  //   }
  // }

  componentDidMount() {
    this.editWPTemplate();
    this.getWhatsaAppNumberList();

    window.fbAsyncInit = function () {
      window.FB.init({
        appId: "675419764769671",
        cookie: true,
        xfbml: true,
        version: "v20.0",
      });
    };

    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");

    window.addEventListener("message", this.sessionInfoListener);
  }
  componentWillUnmount() {
    window.removeEventListener("message", this.sessionInfoListener);
  }

  sessionInfoListener = (event) => {
    if (
      event.origin !== "https://www.facebook.com" &&
      event.origin !== "https://web.facebook.com"
    ) {
      return;
    }

    try {
      const data = JSON.parse(event.data);
      if (data.type === "WA_EMBEDDED_SIGNUP") {
        if (data.event === "FINISH") {
          const { phone_number_id, waba_id } = data.data;
          this.setState({
            phoneNoId: phone_number_id,
            waId: waba_id,
          });
        }
      }
    } catch (error) {
      // Handle non-JSON responses
    }
  };

  getApiCall = async (apiUrl) => {
    try {
      const response = await fetch(apiUrl);
      if (!response.ok) {
        throw new Error("Network response was not ok" + apiUrl);
      }
      const data = await response.json();
      const { access_token } = data;

      if (access_token) {
        this.setState({ accessTokenFlag: 1 });
        const laravelUrl = `${this.laravelAppUrl}?waba_id=${this.state.waId}&phone_numberid=${this.state.phoneNoId}&access_token=${access_token}&config_id=868705554904803`;
        console.log(laravelUrl);
      } else {
        console.log(JSON.stringify(data));

        if (this.state.counter <= 15 && this.state.accessTokenFlag === 0) {
          const newGetTokenUrl = `${this.getTokenUrl}${this.state.getAccessToken}`;
          setTimeout(() => {
            this.getApiCall(newGetTokenUrl);
          }, 4000);
          this.setState((prevState) => ({ counter: prevState.counter + 1 }));
        }
      }
    } catch (error) {
      console.error("Error:", error);
      const laravelAppUrlCatch = `${this.laravelAppUrl}?waba_id=${this.state.waId}&phone_numberid=${this.state.phoneNoId}&error=${error.message}&config_id=868705554904803`;
      this.getApiCall(laravelAppUrlCatch);
    }
  };

  //   launchWhatsAppSignup = () => {
  //     window.FB.login(
  //       (response) => {
  //         if (response.authResponse) {
  //           const accessToken = response.authResponse.code;
  //           this.setState({ getAccessToken: accessToken });
  //           const newGetTokenUrl = `${this.getTokenUrl}${accessToken}`;
  //           setTimeout(() => {
  //             this.getApiCall(newGetTokenUrl);
  //           }, 4000);
  //         } else {
  //           console.log('User cancelled login or did not fully authorize.');
  //         }
  //       },
  //       {
  //         config_id: '868705554904803',
  //         response_type: 'code',
  //         override_default_response_type: true,
  //         extras: {
  //           feature: 'whatsapp_embedded_signup',
  //           sessionInfoVersion: 2,
  //           version: 2,
  //           setup: {
  //             solutionID: '851618553456461',
  //           },
  //         },
  //       }
  //     );
  //   };

  launchWhatsAppSignup = () => {
    if (this.fbInitialized && window.FB) {
      window.FB.login(
        (response) => {
          if (response.authResponse) {
            const accessToken = response.authResponse.code;
            this.setState({ getAccessToken: accessToken });
            const newGetTokenUrl = `${this.getTokenUrl}${accessToken}`;
            setTimeout(() => {
              this.getApiCall(newGetTokenUrl);
            }, 4000);
          } else {
            console.log("User cancelled login or did not fully authorize.");
          }
        },
        {
          config_id: "868705554904803",
          response_type: "code",
          override_default_response_type: true,
          extras: {
            feature: "whatsapp_embedded_signup",
            sessionInfoVersion: 2,
            version: 2,
            setup: {
              solutionID: "851618553456461",
            },
          },
        }
      );
    } else {
      console.log("Facebook SDK not initialized yet.");
    }
  };

  //   launchWhatsAppSignup(userId, usertoken, userName) {
  //     console.log(usertoken);
  //     // Conversion tracking code
  //     // Launch Facebook login
  //     window.FB.login(
  //       function (response) {
  //         let code;
  //         if (response.authResponse) {
  //           // Extract the access token from the response object (not the code)
  //           const accessToken = response.authResponse.accessToken; // Use `accessToken` instead of `code`
  //           console.log(accessToken);

  //           // Ensure `userId`, `usertoken`, and `userName` are correctly defined before sending the request
  //           fetch(`${HOST_URL}/facebook_embeded_signup_pinnacle.php`, {
  //             method: "POST",
  //             headers: {
  //               "Content-Type": "application/json",
  //             },
  //             body: JSON.stringify({
  //               accessToken: accessToken, // Send the correct accessToken
  //               user_id: userId, // Ensure `userId` is defined
  //               token: usertoken, // Ensure `usertoken` is defined
  //               user_name: userName, // Ensure `userName` is defined
  //               method: "insert_token",
  //               remark: "success",
  //             }),
  //           })
  //             .then((response) => response.json())
  //             .then((data) => {
  //               console.log(data);
  //             })
  //             .catch((error) => {
  //               console.error("Error:", error);
  //             });
  //         } else {
  //           console.log("User cancelled login or did not fully authorize.");

  //           // Handle cancellation or incomplete authorization by sending a different remark
  //           fetch(`${HOST_URL}/facebook_embeded_signup_pinnacle.php`, {
  //             method: "POST",
  //             headers: {
  //               "Content-Type": "application/json",
  //             },
  //             body: JSON.stringify({
  //               accessToken: null, // No valid accessToken in this case
  //               user_id: userId, // Ensure `userId` is defined
  //               token: usertoken, // Ensure `usertoken` is defined
  //               user_name: userName, // Ensure `userName` is defined
  //               method: "insert_token",
  //               remark: "cancel", // Indicate cancellation
  //             }),
  //           })
  //             .then((response) => response.json())
  //             .then((data) => {
  //               console.log(data);
  //             })
  //             .catch((error) => {
  //               console.error("Error:", error);
  //             });
  //         }
  //       },
  //       {
  //         scope:
  //           "business_management, whatsapp_business_management, whatsapp_business_messaging",
  //         config_id: "868705554904803", // configuration ID goes here
  //         response_type: "code", // must be set to 'code' for System User access token
  //         override_default_response_type: true, // when true, any response types passed in the "response_type" will take precedence over the default types

  //         extras: {
  //           feature: "whatsapp_embedded_signup",
  //           // Display original flow to user
  //           sessionInfoVersion: 2,
  //           setup: {
  //             // solutionID: "1627222894734353",
  //             solutionID: "851618553456461",
  //           },
  //         },
  //       },
  //       window.addEventListener("message", (event) =>
  //         this.sessionInfoListener(event, userId, usertoken, userName)
  //       )
  //     );
  //   }

  // Function to fetch shared WhatsApp account IDs
  // fetchSharedWhatsAppAccountIds = (accessToken, uid, utoken, uname) => {
  //   window.FB.api(
  //     "/debug_token",
  //     { input_token: accessToken, access_token: "your-access-token" }, // Replace with your actual access token
  //     (response) => {
  //       console.log("API Response:", response);
  //       // Handle AJAX request or API call similarly
  //       fetch("${HOST_URL}/facebook_embeded_signup.php", {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify({
  //           accessToken,
  //           response,
  //           user_id: uid,
  //           token: utoken,
  //           user_name: uname,
  //           method: "insert_success",
  //         }),
  //       })
  //         .then((response) => response.json())
  //         .then((data) => {
  //           // Process the response data if needed
  //         })
  //         .catch((error) => console.error("Error:", error));

  //       // Further processing based on response
  //       if (
  //         response &&
  //         response.data &&
  //         response.data.granular_scopes &&
  //         !response.error
  //       ) {
  //         const waScope = response.data.granular_scopes.find(
  //           ({ scope }) => scope === "whatsapp_business_management"
  //         );
  //         if (waScope != null && waScope.target_ids != null) {
  //           // Proceed with WhatsApp Business API integration
  //         }
  //       }
  //     }
  //   );
  // };

  handleCountryChange(telNumber, selectedCountry) {
    const isoCountryCode = selectedCountry.iso2;

    // Update both country code and name separately in the state
    this.setState(
      {
        country_code: selectedCountry.dialCode, // Update country code
        country_name: isoCountryCode, // Update country name
      },
      () => {
        // Callback function to ensure state is updated before rendering
        console.log(
          "State updated:",
          this.state.country_code,
          this.state.country_name
        );
      }
    );
  }

  editWPTemplate() {
    fetch(
      `${HOST_URL}/wp_profile.php?user_id=${this.state.user_id}&method=retrieveid&id=${this.props.match.params.id}&token=${this.state.login_user.token}`
    ).then((response) => {
      response.json().then((result) => {
        // console.log("data", result.data);
        if (result.success === true) {
          this.setState({
            comp_name: result.data[0].comp_name,
            brand_number: result.data[0].brand_number,
            // number_not_active: result.data[0].number_not_active,
            address: result.data[0].address,
            description: result.data[0].description,
            category: result.data[0].category,
            live_image_url: result.data[0].image_url,
            email: result.data[0].email,
            profile_status: result.data[0].profile_status,
            fb_businness_id: result.data[0].fb_businness_id,
            chooseFbOption: result.data[0].fb_businness_id ? "yes" : null,
            account_status: result.data[0].account_status,
            country_code: result.data[0].country_code,
            country_name: result.data[0].country_name,
            business_website: result.data[0].business_website,
            displayname: result.data[0].display_name,
            whatsapp_no_type: result.data[0].number_type,
            two_factor_authentication: result.data[0].two_factor,
            waba_id: result.data[0].waba_id,
            aboutus: result.data[0].about_us,
          });
        } else {
        }
      });
    });
  }

  enableWhatsapp() {
    document.querySelector("body").scrollTo(0, 0);
    let status = this.isValid();
    if (status === false) {
      return;
    }
    const data = new FormData();
    data.append("image_url", this.state.image_url);
    data.append("comp_name", this.state.comp_name);
    data.append("brand_number", this.state.brand_number);
    // data.append("number_not_active", this.state.number_not_active);
    data.append("business_website", this.state.business_website);
    data.append("about_us", this.state.aboutus);
    data.append("displayname", this.state.displayname);
    data.append("whatsapp_no_type", this.state.whatsapp_no_type);
    data.append("two_factor_auth", this.state.two_factor_authentication);
    data.append("waba_id", this.state.waba_id);
    data.append("address", this.state.address);
    data.append("description", this.state.description);
    data.append("category", this.state.category);
    data.append("email", this.state.email);
    // data.append("profile_status", this.state.profile_status);
    data.append("fb_businness_id", this.state.fb_businness_id);
    data.append("user_id", this.state.user_id);
    data.append("token", this.state.login_user.token);
    data.append("country_code", this.state.country_code);
    data.append("country_name", this.state.country_name);
    data.append("method", "create");

    fetch(`${HOST_URL}/wp_profile.php`, {
      method: "POST",
      body: data,
    }).then((result) => {
      result.json().then((resp) => {
        // console.log(resp)
        if (resp.success === true) {
          this.setState({
            // successMessage: true,
            // bgColor: "alert alert-success alert-dismissible",
            // errorMessage: resp.message,
            manageProfile: false,
            withFacebook: true,
            step: this.state.step + 1,
          });
          setTimeout(function () {
            // window.location = "/dashboard/whatsapp";
            this.setState({
              successMessage: false,
            });
          }, 200);
        } else {
          this.setState({
            successMessage: true,
            bgColor: "alert alert-danger alert-dismissible",
            errorMessage: resp.message,
          });
        }
      });
    });
  }

  updateWPTemplate() {
    document.querySelector("body").scrollTo(0, 0);
    let status = this.isValid();
    if (status === false) {
      return;
    }
    const data = new FormData();
    data.append("comp_name", this.state.comp_name);
    data.append("brand_number", this.state.brand_number);
    // data.append("number_not_active", this.state.number_not_active);
    data.append("about_us", this.state.aboutus);
    data.append("business_website", this.state.business_website);
    data.append("displayname", this.state.displayname);
    data.append("whatsapp_no_type", this.state.whatsapp_no_type);
    data.append("two_factor_auth", this.state.two_factor_authentication);
    data.append("waba_id", this.state.waba_id);
    data.append("address", this.state.address);
    data.append("description", this.state.description);
    data.append("category", this.state.category);
    data.append("email", this.state.email);
    data.append("profile_status", this.state.profile_status);
    data.append("user_id", this.state.user_id);
    data.append("token", this.state.login_user.token);
    data.append("method", "update");
    data.append("id", this.props.match.params.id);
    data.append("country_code", this.state.country_code);
    data.append("country_name", this.state.country_name);
    fetch(`${HOST_URL}/wp_profile.php`, {
      method: "POST",
      body: data,
    }).then((result) => {
      result.json().then((resp) => {
        // console.log(resp)
        if (resp.success === true) {
          this.setState({
            // successMessage: true,
            // bgColor: "alert alert-success alert-dismissible",
            // errorMessage: resp.message,
            step: this.state.step + 1,
            isopenPopup: true,
          });
          // setTimeout(function () {
          //   window.location.reload();
          // }, 2000);
        } else {
          this.setState({
            successMessage: true,
            bgColor: "alert alert-danger alert-dismissible",
            errorMessage: resp.message,
          });
        }
      });
    });
  }

  fileChangedHandler(e) {
    const selectedFile = e.target.files[0]; // Store the selected file

    if (selectedFile) {
      const image = new Image();
      image.src = URL.createObjectURL(selectedFile);

      image.onload = () => {
        if (image.width >= 192 && image.height >= 192) {
          this.setState({
            image_url: selectedFile,
            successMessage: true,
            bgColor: "alert alert-success alert-dismissible",
            errorMessage:
              "Uploaded display Picture show Successfully. Now pls click update Logo Button.",
          });
        } else {
          this.setState({
            successMessage: true,
            bgColor: "alert alert-danger alert-dismissible",
            errorMessage:
              "Image resolution should be greater than 192x192 pixels.",
          });
        }
      };
    }
  }
  upateProfileimg() {
    document.querySelector("body").scrollTo(0, 0);
    const data = new FormData();
    data.append("image_url", this.state.image_url);
    data.append("user_id", this.state.user_id);
    data.append("token", this.state.login_user.token);
    data.append("method", "upload_logo");
    data.append("id", this.props.match.params.id);

    fetch(`${HOST_URL}/wp_profile.php`, {
      method: "POST",
      body: data,
    }).then((result) => {
      result.json().then((resp) => {
        //console.log(resp)
        //alert(resp);
        if (resp.success === true) {
          this.setState({
            successMessage: true,
            bgColor: "alert alert-success alert-dismissible",
            errorMessage: resp.message,
          });
          setTimeout(function () {
            window.location.reload();
          }, 2000);
        } else {
          this.setState({
            successMessage: true,
            bgColor: "alert alert-danger alert-dismissible",
            errorMessage: resp.message,
          });
        }
      });
    });
  }

  whatsAppUses(e) {
    this.setState({ number_not_active: e.target.value });
  }

  responseFacebook = (response) => {
    console.log(response);

    const loginWithFacebook = {
      accessToken: response.accessToken,
      method: "setup",
      api_response: response,
    };
    fetch(`${HOST_URL}/whatsapp_setup.php`, {
      method: "post",
      headers: {
        "content-Type": "application/json",
      },
      body: JSON.stringify(loginWithFacebook),
    }).then((result) => {
      result.json().then((resp) => {
        console.log(resp.success);
      });
    });
  };

  brandNoHandler = (e) => {
    let number = e.target.value;
    let length = number.toString().length;
    if (length < 11) {
      this.setState({
        brand_number: e.target.value,
      });
    }
  };

  redirect = () => {
    window.location.href = "/dashboard/whatsapp";
  };

  isValid = () => {
    // if (this.state.fb_businness_id === "") {
    //   this.setState({
    //     successMessage: true,
    //     bgColor: "alert alert-danger alert-dismissible",
    //     errorMessage: "Please enter your facebook business id",
    //   });
    //   return false;
    // }

    if (this.state.comp_name === "") {
      this.setState({
        successMessage: true,
        bgColor: "alert alert-danger alert-dismissible",
        errorMessage: "Please enter your Company name",
      });
      return false;
    }
    if (this.state.displayname === "") {
      this.setState({
        successMessage: true,
        bgColor: "alert alert-danger alert-dismissible",
        errorMessage: "Please enter your display name",
      });
      return false;
    }

    if (this.state.brand_number === "") {
      this.setState({
        successMessage: true,
        bgColor: "alert alert-danger alert-dismissible",
        errorMessage: "Please enter your Proposed WhatsApp Number",
      });
      return false;
    }
    if (this.state.whatsapp_no_type === "") {
      this.setState({
        successMessage: true,
        bgColor: "alert alert-danger alert-dismissible",
        errorMessage: "Please enter your Proposed Whatsaap No Type",
      });
      return false;
    }

    if (
      this.state.two_factor_authentication === "" &&
      this.state.whatsapp_no_type === "migration"
    ) {
      this.setState({
        successMessage: true,
        bgColor: "alert alert-danger alert-dismissible",
        errorMessage: "Please enter your Two factor authentication status",
      });
      return false;
    }
    if (this.state.address === "") {
      this.setState({
        successMessage: true,
        bgColor: "alert alert-danger alert-dismissible",
        errorMessage: "Please enter your business profile address",
      });
      return false;
    }

    if (this.state.description === "") {
      this.setState({
        successMessage: true,
        bgColor: "alert alert-danger alert-dismissible",
        errorMessage: "Please enter your business description",
      });
      return false;
    }

    if (this.state.aboutus === "") {
      this.setState({
        successMessage: true,
        bgColor: "alert alert-danger alert-dismissible",
        errorMessage: "Please enter about us",
      });
      return false;
    }
    if (this.state.category === "") {
      this.setState({
        successMessage: true,
        bgColor: "alert alert-danger alert-dismissible",
        errorMessage: "Please choose category",
      });
      return false;
    }
    if (this.state.email === "") {
      this.setState({
        successMessage: true,
        bgColor: "alert alert-danger alert-dismissible",
        errorMessage: "Please enter your email",
      });
      return false;
    }
  };

  handleManageProfile = () => {
    this.setState({
      manageProfile: true,
      manageLogo: false,
      withFacebook: false,
    });
  };

  handleManageLogo = () => {
    this.setState({
      manageLogo: true,
      manageProfile: false,
      withFacebook: false,
    });
  };

  handleWithFacebook = () => {
    this.setState({
      withFacebook: true,
      manageProfile: false,
      manageLogo: false,
    });
  };

  getWhatsaAppNumberList = async () => {
    WhstappAccountList().then((res) => {
      if (res.data.success === true) {
        this.setState({ whatsappNumber: res.data.data, setIsLoading: false });
      } else {
        this.setState({ ErrorResponse: res.data, setIsLoading: false });
      }
    });
  };

  OpenPopup = () => {
    this.setState({ manageLogo: true });
  };

  render() {
    return (
      <div className="wrapper">
        <DashboardLeftMenu />
        <div className="main">
          <DashboardHeader />
          <main className="content">
            <div className="container-fluid p-0">
              <div className="row mb-2 mb-xl-3">
                <div className="col-auto d-none d-sm-block">
                  <h3>
                    {this.props.match.params.id
                      ? "WhatsApp Edit and Update"
                      : "WhatsApp Onboarding"}{" "}
                  </h3>
                </div>
                {this.props.match.params.id ? (
                  <div className="col-auto ml-auto text-right mt-n1">
                    <span
                      className="btn btn-info btn-sm"
                      onClick={this.OpenPopup}
                    >
                      <FontAwesomeIcon icon={faEdit} color="" /> Edit Logo
                    </span>
                    {/* <button type="button" onClick={this.OpenPopup} className="btn btn-info">Edit Logo</button> */}
                  </div>
                ) : null}

                <div
                  className={
                    this.props.match.params.id
                      ? "col-auto text-right mt-n1"
                      : "col-auto ml-auto text-right mt-n1"
                  }
                >
                  <Link
                    className="btn btn-primary btn-sm"
                    to="/dashboard/whatsapp"
                  >
                    <FontAwesomeIcon icon={faBackward} color="" /> Go Back
                  </Link>
                </div>
              </div>

              <div className="card">
                <div className="card-body">
                  <div id="msform">
                    <ul id="progressbar">
                      {this.state.step === 1 ? (
                        <>
                          <li className="active" style={{ marginLeft: "30vh" }}>
                            Enter Details
                          </li>
                          <li>Connect with Facebook</li>
                          {/* <li>Campaign Template </li> */}
                          {/* <li> Preview Campaign </li> */}
                        </>
                      ) : this.state.step === 2 ? (
                        <>
                          <li className="active" style={{ marginLeft: "30vh" }}>
                            Enter Details
                          </li>
                          <li className="active">Connect with Facebook </li>
                          {/* <li>Campaign Template </li> */}
                          {/* <li> Preview Campaign </li> */}
                        </>
                      ) : null}
                    </ul>
                  </div>
                </div>
              </div>

              {this.state.successMessage ? (
                <div className={this.state.bgColor} role="alert">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                  <div className="">{this.state.errorMessage}</div>
                </div>
              ) : null}

              <div className="">
                <div className="api-doc  bg-white  shadow mb-0 text-left">
                  <div className="whatsapp-unsubscribe">
                    {/* <button
                      style={{
                        border: "none",
                        borderRadius: "20px",
                        backgroundColor: this.state.manageProfile ? "#3F3966" : "white",
                        color: this.state.manageProfile ? "white" : "black",
                        fontSize: "15px",
                        marginTop: "10px",
                        marginLeft: "10px",
                        padding: "8px",
                      }}
                      type="button"
                      onClick={this.handleManageProfile}
                    >
                      Manage Profile
                    </button>

                    <button style={{ border: "none", borderRadius: "20px 20px 20px 20px", backgroundColor: this.state.manageLogo ? "#3F3966" : "white", color: this.state.manageLogo ? "white" : "black", fontSize: "15px", padding: "8px 8px 8px 8px" }} type="button" onClick={this.handleManageLogo} className="ml-2" >Manage Logo</button>
                    <button type="button" style={{ border: "none", borderRadius: "20px 20px 20px 20px", backgroundColor: this.state.withFacebook ? "#3F3966" : "white", color: this.state.withFacebook ? "white" : "black", fontSize: "15px", padding: "8px 8px 8px 8px" }} onClick={this.handleWithFacebook} className="ml-3">With Facebook</button> */}
                    {this.state.step === 1 ? (
                      <>
                        <div className="row p-2">
                          <form
                            id="formViewOnly"
                            className={
                              this.props.match.params.id
                                ? "col-md-12"
                                : "col-md-12"
                            }
                          >
                            <div className="card-body">
                              <div className="form-row">
                                <div className="form-group col-md-6">
                                  <label className="col-form-label">
                                    Do You have FB Business Manager ID
                                    {/* <span style={{ color: "red" }}>*</span> */}
                                  </label>
                                  <select
                                    name="selectfbid"
                                    className="form-control"
                                    value={this.state.chooseFbOption}
                                    onChange={(e) =>
                                      this.setState({
                                        chooseFbOption: e.target.value,
                                      })
                                    }
                                  >
                                    <option value="">Select Option</option>
                                    <option value="yes">Yes</option>
                                    <option value="no">No</option>
                                  </select>
                                </div>
                                {this.state.chooseFbOption === "yes" && (
                                  <div className="form-group col-md-6">
                                    <label className="col-form-label">
                                      FB Business Manager ID
                                      {/* <span style={{ color: "red" }}>*</span> */}
                                    </label>
                                    <input
                                      className="form-control"
                                      defaultValue={this.state.fb_businness_id}
                                      onChange={(event) =>
                                        this.setState({
                                          fb_businness_id: event.target.value,
                                        })
                                      }
                                      disabled={!!this.props.match.params.id} // Simplified the condition
                                      placeholder="Example : 34243433454332"
                                      type="text"
                                      name="emailunsub"
                                      id="flexCheckDefault"
                                    />
                                    <small className="text-info">
                                      (This is the first step for us to begin,
                                      Please complete this at PRIORITY)
                                    </small>
                                  </div>
                                )}

                                <div className="row">
                                  <div className="form-group col-md-6">
                                    <label className="col-form-label">
                                      Company Name:
                                      <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <input
                                      className="form-control"
                                      defaultValue={this.state.comp_name}
                                      onChange={(event) =>
                                        this.setState({
                                          comp_name: event.target.value,
                                        })
                                      }
                                      placeholder="Company Pvt. Ltd."
                                      type="text"
                                      name="emailunsub"
                                      id="flexCheckDefault"
                                    />
                                  </div>
                                  <div className="form-group col-md-6">
                                    <label className="col-form-label">
                                      Display Name
                                      <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <input
                                      className="form-control"
                                      value={this.state.displayname}
                                      onChange={(event) =>
                                        this.setState({
                                          displayname: event.target.value,
                                        })
                                      }
                                      placeholder="Display Name"
                                      type="text"
                                    />
                                  </div>
                                </div>
                                {/* <div className="form-group col-md-12">
                                    <label className="col-form-label">
                                      No. not active on WhatsApp
                                    </label>
                                    <select
                                      value={this.state.number_not_active}
                                      onChange={this.whatsAppUses}
                                      className="form-control"
                                    >
                                      <option value=""> Select </option>
                                      <option value="Yes">Yes</option>
                                      <option value="No">No</option>
                                    </select>
                                  </div> */}

                                <div className="row">
                                  <div className="form-group col-md-6">
                                    <label
                                      for="colFormLabel"
                                      className="col-form-label"
                                    >
                                      Proposed WhatsApp Number
                                      <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <div className="input-group mb-2">
                                      <div className="input-group-prepend">
                                        <div className="">
                                          <ReactTelInput
                                            defaultCountry={
                                              this.state.country_name
                                            }
                                            flagsImagePath={flags}
                                            onSelectFlag={(country, iso2) =>
                                              this.handleCountryChange(null, {
                                                iso2,
                                                dialCode: country,
                                              })
                                            }
                                          />

                                          <input
                                            type="hidden"
                                            value={this.state.country_code}
                                            onChange={(event) =>
                                              this.setState({
                                                country_code:
                                                  event.target.value,
                                              })
                                            }
                                            className="form-control"
                                            placeholder="Country Code"
                                          />
                                        </div>
                                      </div>

                                      <input
                                        type="number"
                                        value={this.state.brand_number}
                                        onChange={this.brandNoHandler}
                                        className="form-control number-input"
                                        disabled={
                                          this.props.match.params.id
                                            ? true
                                            : false
                                        }
                                        placeholder="+91XXXXXXXXXX"
                                      />
                                    </div>
                                  </div>
                                  <div className="form-group col-md-6">
                                    <label className="col-form-label">
                                      Proposed Whatsaap No Type:{" "}
                                      <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <select
                                      value={this.state.whatsapp_no_type}
                                      onChange={(event) =>
                                        this.setState({
                                          whatsapp_no_type: event.target.value,
                                        })
                                      }
                                      name="Prop_whatsapp_no"
                                      className="form-control cursor-pointer"
                                    >
                                      <option>Select Type</option>
                                      <option value="newnumber">
                                        New Number
                                      </option>
                                      <option value={"migration"}>
                                        Migration
                                      </option>
                                    </select>
                                    {/* <input
                                      className="form-control"
                                      defaultValue={this.state.display_name}
                                      onChange={(event) =>
                                        this.setState({
                                          display_name: event.target.value,
                                        })
                                      }
                                      placeholder="Display Name"
                                      type="text"
                                      name="displayname"
                                      id="flexCheckDefault"
                                    /> */}
                                  </div>
                                  {this.state.whatsapp_no_type ===
                                    "migration" && (
                                    <div className="form-group col-md-6">
                                      <label className="col-form-label">
                                        Two factor authentication status
                                        <span style={{ color: "red" }}>*</span>
                                      </label>
                                      <select
                                        value={
                                          this.state.two_factor_authentication
                                        }
                                        onChange={(event) =>
                                          this.setState({
                                            two_factor_authentication:
                                              event.target.value,
                                          })
                                        }
                                        name="Prop_whatsapp_no"
                                        className="form-control"
                                      >
                                        <option>Select</option>
                                        <option value="enable">Enable</option>
                                        <option value="disable">disable</option>
                                      </select>
                                    </div>
                                  )}
                                </div>

                                <div className="form-group col-md-12">
                                  <label className="col-form-label">
                                    Business Profile Address :
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <textarea
                                    defaultValue={this.state.address}
                                    onChange={(event) =>
                                      this.setState({
                                        address: event.target.value,
                                      })
                                    }
                                    placeholder="Enter Full Address (Maximum of 256 characters)"
                                    className="form-control"
                                  ></textarea>
                                </div>

                                <div className="form-group col-md-12">
                                  <label className="col-form-label">
                                    Business Description:
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <textarea
                                    defaultValue={this.state.description}
                                    maxLength={168}
                                    onChange={(event) =>
                                      this.setState({
                                        description: event.target.value,
                                      })
                                    }
                                    placeholder="Enter about your business (Maximum of 168 characters)"
                                    className="form-control"
                                  ></textarea>
                                </div>

                                <div className="form-group col-md-12">
                                  <label className="col-form-label">
                                    About Us:
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <textarea
                                    defaultValue={this.state.aboutus}
                                    maxLength={139}
                                    onChange={(event) =>
                                      this.setState({
                                        aboutus: event.target.value,
                                      })
                                    }
                                    placeholder=""
                                    className="form-control"
                                  ></textarea>
                                </div>

                                <div className="form-group col-md-6">
                                  <label className="col-form-label">
                                    Business Category:
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <select
                                    value={this.state.category}
                                    onChange={(event) =>
                                      this.setState({
                                        category: event.target.value,
                                      })
                                    }
                                    name="Prop_whatsapp_no"
                                    className="form-control cursor-pointer"
                                  >
                                    <option>Select Business Category</option>
                                    <option value="apparel">Apparel</option>
                                    <option value="auto">Auto</option>
                                    <option value="beauty">Beauty</option>
                                    <option value="education">Education</option>
                                    <option value="entertainment">
                                      Entertainment
                                    </option>
                                    <option value="eventplan">
                                      Event Plan
                                    </option>
                                    <option value="finance">Finance</option>
                                    <option value="govt">Govt.</option>
                                    <option value="grocery">Grocery</option>
                                    <option value="health">Health</option>
                                    <option value="hotel">Hotel</option>
                                    <option value="non_profit">
                                      Non-Profit
                                    </option>
                                    <option value="prof_services">
                                      Prof-Services
                                    </option>
                                    <option value="restaurant">
                                      Restaurant
                                    </option>
                                    <option value="retail">Retail</option>
                                    <option value="travel">Travel</option>
                                    <option value="other">Other</option>
                                  </select>
                                </div>

                                <div className="form-group col-md-6">
                                  <label className="col-form-label">
                                    Business Email:
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <input
                                    className="form-control"
                                    defaultValue={this.state.email}
                                    onChange={(event) =>
                                      this.setState({
                                        email: event.target.value,
                                      })
                                    }
                                    placeholder="company@mail.com"
                                    type="text"
                                    name="emailunsub"
                                    id="flexCheckDefault"
                                  />
                                </div>

                                {/* <div className="form-group col-md-12">
                                    <label className="col-form-label">
                                      WhatsApp Profile Status:
                                    </label>
                                    <input
                                      className="form-control"
                                      defaultValue={this.state.profile_status}
                                      onChange={(event) =>
                                        this.setState({
                                          profile_status: event.target.value,
                                        })
                                      }
                                      placeholder="The max length for the string is 139 characters."
                                      type="text"
                                      name="emailunsub"
                                      id="flexCheckDefault"
                                    />
                                  </div> */}
                                <div className="row">
                                  <div className="form-group col-md-6">
                                    <label className="col-form-label">
                                      Business Website
                                    </label>
                                    <input
                                      className="form-control"
                                      defaultValue={this.state.business_website}
                                      onChange={(event) =>
                                        this.setState({
                                          business_website: event.target.value,
                                        })
                                      }
                                      placeholder="https://www.company.com"
                                      type="text"
                                      name="emailunsub"
                                      id="flexCheckDefault"
                                    />
                                  </div>

                                  <div className="form-group col-md-6">
                                    <label className="col-form-label">
                                      Waba ID
                                    </label>
                                    <input
                                      type="number"
                                      value={this.state.waba_id}
                                      disabled={
                                        this.props.match.params.id
                                          ? true
                                          : false
                                      }
                                      onChange={(e) => {
                                        this.setState({
                                          waba_id: e.target.value,
                                        });
                                      }}
                                      className="form-control number-input"
                                      placeholder="Enter waba id"
                                    />
                                  </div>
                                </div>

                                {this.props.match.params.id ? (
                                  <div className="text-center col-md-12 m-auto">
                                    <button
                                      type="button"
                                      onClick={() => this.updateWPTemplate()}
                                      className="btn btn-sm btn-primary mt-3"
                                    >
                                      Update Profile
                                    </button>
                                  </div>
                                ) : (
                                  <div className="text-center col-md-12 m-auto">
                                    <input
                                      type="button"
                                      onClick={() => this.enableWhatsapp()}
                                      className="btn btn-sm btn-primary mt-3"
                                      value="Request Now"
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          </form>
                          {/* {this.props.match.params.id ? null : (
                                                        <div className="col-md-4 pl-0 mt-5">
                                                            <strong className="mb-4 d-block">
                                                                To create facebook Business Manager ID follow
                                                                the steps:
                                                            </strong>
                                                            <ol style={{ lineHeight: 2 }}>
                                                                <li>
                                                                    Create Business manager account by logging
                                                                    into{" "}
                                                                    <a
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                        href="https://business.facebook.com/"
                                                                    >
                                                                        business.facebook.com
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    Use your fb account and start creating
                                                                    account by clicking create business account
                                                                </li>
                                                                <li>
                                                                    Enter legal entity name and official email
                                                                    id
                                                                </li>
                                                                <li> Put legal addresses, phone numbers</li>
                                                                <li>
                                                                    After submitting details. click business
                                                                    setting and Business info
                                                                </li>
                                                                <li>
                                                                    Share Business manager ID so I can create
                                                                    your whatsapp account
                                                                </li>
                                                                <li>
                                                                    You need to go to request `{">"}` received
                                                                    to approve Netcore’s request
                                                                </li>
                                                                <li>
                                                                    Go to `{">"}` Security centre `{">"}` Start
                                                                    verification
                                                                </li>
                                                                <li>
                                                                    Facebook will ask legal documents for
                                                                    verifying Entity name, Address, Phone
                                                                    number, domain - keep below link documents
                                                                    handy and upload it
                                                                </li>
                                                                <li>
                                                                    Once your account is verified confirm me
                                                                    back so we will give back whatsapp
                                                                    confirmation
                                                                </li>
                                                            </ol>
                                                        </div>
                                                    )} */}
                        </div>
                        {/* {!this.state.manageLogo ? (
                      <>
                        <div class="card-body">
                          {this.props.match.params.id ? (
                            <form
                              id="formViewOnly"
                              className={
                                this.props.match.params.id
                                  ? "col-md-12"
                                  : "col-md-8"
                              }
                            >
                              <div class="row">
                                <div class="col-md-6">
                                  <label className="col-form-label">
                                    Display Picture:
                                  </label>
                                  <input
                                    type="file"
                                    onChange={this.fileChangedHandler}
                                    class="form-control"
                                    accept="image/jpeg"
                                  />

                                  <small>
                                    (Please upload JPG image of resolution
                                    192 pixel * 192 pixel for best view)
                                  </small>
                                </div>
                                <div className="col-md-6">
                                  <label className="col-form-label">
                                    Uploaded Display Picture:
                                  </label>

                                  <img
                                    src={this.state.live_image_url}
                                    alt="Uploaded"
                                    class="whatsapp_profile_img"
                                  />
                                </div>
                                <div className="col-md-4">
                                  <button
                                    type="button"
                                    onClick={() => this.upateProfileimg()}
                                    className="btn btn-sm btn-primary mt-3"
                                  >
                                    Update Logo
                                  </button>
                                </div>
                              </div>
                            </form>
                          ) : (
                            <div class="badge badge-danger">
                              Please fill the profile data First
                            </div>
                          )}
                        </div>
                      </>
                    ) : null} */}
                      </>
                    ) : null}

                    {this.state.step === 2 ? (
                      <>
                        <div className="p-5 text-center">
                          <div className="row justify-content-center">
                            <div className="col-12 mb-3 d-flex justify-content-center align-items-center">
                              <img
                                src={metalogo}
                                alt="Meta Logo"
                                className="mr-1"
                                style={{ width: "230px" }}
                              />
                              <img
                                src={arrowLeftRight}
                                alt="Arrow Icon"
                                style={{ width: "70px" }}
                              />
                              <img
                                src={authkeylogonew}
                                alt="Logo"
                                className="ml-4"
                                style={{ width: "230px" }}
                              />
                            </div>
                          </div>
                          <div className="mt-3">
                            <iframe
                              src={`https://console.authkey.io/wabap/index.php?user_id=${this.state.user_id}&user_name=${this.state.username}`}
                              width="600"
                              height="400"
                              title="Example Iframe"
                              frameBorder="0"
                            />
                          </div>
                        </div>
                      </>
                    ) : null}
                    {this.state.isopenPopup && (
                      <div
                        className="modal fade show"
                        style={{
                          display: "block",
                          backgroundColor: "rgba(0, 0, 0, 0.5)",
                        }}
                        tabIndex="-1"
                      >
                        <div className="modal-dialog modal-dialog-centered">
                          <div className="modal-content">
                            <div className="modal-header">
                              {/* <h5 className="modal-title">Change Status</h5> */}
                              <button
                                type="button"
                                className="close"
                                onClick={this.redirect}
                              >
                                <span>&times;</span>
                              </button>
                            </div>
                            <div className="modal-body">
                              <h6 style={{ textAlign: "center" }}>
                                Edit Successfully
                              </h6>
                            </div>
                            <div className="modal-footer">
                              <button
                                type="button"
                                className="btn btn-info"
                                onClick={() =>
                                  this.setState({ isopenPopup: false })
                                }
                              >
                                Continue to Embedded Signup
                              </button>
                              <button
                                type="button"
                                className="btn btn-success"
                                onClick={this.redirect}
                              >
                                Close
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {this.state.manageLogo === true && (
                <div
                  className="modal fade show"
                  tabIndex="-1"
                  style={{ display: "block" }}
                  aria-labelledby="popupLabel"
                  aria-hidden="true"
                >
                  <div
                    className="modal-dialog modal-dialog-centered"
                    style={{ maxWidth: "90%" }}
                  >
                    <div className="modal-content">
                      <div className="modal-header">
                        <div className="col-6">
                          <h5 className="modal-title" id="popupLabel">
                            Update Logo
                          </h5>
                        </div>

                        {/* <button type="button" className="btn-close" onClick={() => this.setState({ manageLogo: false })} aria-label="Close"></button> */}
                        <div className="col-6">
                          <button
                            type="button"
                            onClick={() => this.upateProfileimg()}
                            className="btn btn-primary float-right"
                          >
                            Update Logo
                          </button>
                          <button
                            type="button"
                            onClick={() => this.setState({ manageLogo: false })}
                            className="btn btn-secondary float-right mr-2"
                          >
                            Close
                          </button>
                        </div>
                      </div>
                      <div
                        className="modal-body"
                        style={{ maxHeight: "400px", overflowY: "auto" }}
                      >
                        <div className="card-body">
                          {this.props.match.params.id ? (
                            <form id="formViewOnly" className="row">
                              <div className="col-12 col-md-6 mb-3">
                                <label className="col-form-label">
                                  Display Picture:
                                </label>
                                <input
                                  type="file"
                                  onChange={this.fileChangedHandler}
                                  className="form-control"
                                  accept="image/jpeg"
                                />
                                <small
                                  style={{ fontSize: "12px" }}
                                  className="form-text text-muted"
                                >
                                  (Please upload a JPG image of resolution 192px
                                  * 192px for best view)
                                </small>
                              </div>
                              <div className="col-12 col-md-6 mb-3">
                                <label className="col-form-label">
                                  Uploaded Display Picture:
                                </label>
                                <img
                                  src={this.state.live_image_url}
                                  alt="Uploaded"
                                  className="img-fluid rounded"
                                />
                              </div>
                            </form>
                          ) : (
                            <div className="badge badge-danger">
                              Please fill the profile data first
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </main>
          <DashboardFooter />
        </div>
      </div>
    );
  }
}

export default WhatsappEnabale;
