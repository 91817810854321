import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import SendWhstappMessagepop from "../src/components/Dashboard/whatsapp/components/NewReplyPopUp";
// stanmax componenet
import StanmaxRoutes from "./components/Utils/StanmaxRoutes";
import StanmaxConv from "./components/Dashboard/stanmaxChildren/StanmaxConv";
// Pubulic View Component
import Login from "./components/site/Login";
import Signup from "./components/site/Signup";
import ForgotPassword from "./components/site/ForgotPassword";
// Admin view component
import Dashboard from "./components/Dashboard/Dashboard";
import Error from "../src/components/Dashboard/Error";
//Project Component
import CreateProject from "./components/Dashboard/project/CreateProject";
import ProjectList from "./components/Dashboard/project/ProjectList";
import EventList from "./components/Dashboard/event/EventList";

import FallbackList from "./components/Dashboard/fallback/FallbackList";

//Event Component
import CreateEvent from "./components/Dashboard/event/CreateEvent";
import CreateFallBack from "./components/Dashboard/fallback/CreateFallBack";
import CreateDirectEvent from "./components/Dashboard/event/CreateDirectEvent";
import ProjectEventList from "./components/Dashboard/project/ProjectEventList";

// Reports Component
import ReportsDetails from "./components/Dashboard/report/ReportsDetails";
import Reports from "./components/Dashboard/report/Reports";

// Template Component
import SmsTemplate from "./components/Dashboard/sms/SmsTemplate";
import VoiceTemplate from "./components/Dashboard/voice/VoiceTemplate";
import EmailTemplate from "./components/Dashboard/email/EmailTemplate";
import SmsTemplateList from "./components/Dashboard/sms/SmsTemplateList";
import DltTemplateList from "./components/Dashboard/dltSetup/DltTemplateList";
import VoiceTemplateList from "./components/Dashboard/voice/VoiceTemplateList";
import EmailTemplateList from "./components/Dashboard/email/EmailTemplateList";
import SmsReports from "./components/Dashboard/sms/SmsReports";
import EntityByPass from "./components/Admin/EntityByPass";

// Profile Component
import AwsSetup from "./components/Dashboard/aws/AwsSetup";
import AwsFromEmail from "./components/Dashboard/aws/AwsFromEmail";
import MyProfile from "./components/Dashboard/profile/MyProfile";
import ChangePassword from "./components/Dashboard/profile/ChangePassword";
import CreateSenderID from "./components/Dashboard/sms/CreateSenderID";
import EntityID from "./components/Dashboard/dltSetup/EntityID";
import TemplateManagement from "./components/Dashboard/dltSetup/TemplateManagement";
import Logout from "./components/site/Logout";
import TransactionPayment from "./components/Dashboard/profile/TransactionPayment";
import TransactionHistory from "./components/Dashboard/profile/TransactionHistory";
import PaymentSuccess from "./components/Dashboard/payment/PaymentSuccess";
import PaymentFail from "./components/Dashboard/payment/PaymentFail";
import BillingHistory from "./components/Dashboard/profile/BillingHistory";
import Support from "./components/Dashboard/support/Support";
import CreateNewTickets from "./components/Dashboard/support/CreateNewTickets";
import SupportHistory from "./components/Dashboard/support/SupportHistory";
import WebHook from "./components/Dashboard/webhook/WebHook";
import WebHookReport from "./components/Dashboard/webhook/WebHookReport";
import SmsPrice from "./components/Dashboard/sms/SmsPrice";
import SmsApiDashboard from "./components/Dashboard/sms/SmsApiDashboard";
import WhatsAppAPIDashboard from "./components/Dashboard/whatsapp/WhatsAppAPIDashboard";
import SendSms from "./components/Dashboard/sms/SendSms";
import SmsDetailReports from "./components/Dashboard/sms/SmsDetailReports";
import SendVoice from "./components/Dashboard/voice/SendVoice";
import VoiceApiDashboard from "./components/Dashboard/voice/VoiceApiDashboard";
import VoicePriceList from "./components/Dashboard/voice/VoicePriceList";
import VoiceReports from "./components/Dashboard/voice/VoiceReports";
import VoiceDetailReports from "./components/Dashboard/voice/VoiceDetailReports";
import SendEmail from "./components/Dashboard/email/SendEmail";
import EmailApiDashboard from "./components/Dashboard/email/EmailApiDashboard";
import EmailPrice from "./components/Dashboard/email/EmailPrice";
import EmailReports from "./components/Dashboard/email/EmailReports";
import EmailDetailReports from "./components/Dashboard/email/EmailDetailReports";
import CallerID from "./components/Dashboard/voice/CallerID";
import DashboardAPi from "./components/Dashboard/apiDoc/DashboardAPi";
import EmailHTMLEditor from "./components/Dashboard/email/EmailHTMLEditor";
import EmailCampaign from "./components/Dashboard/email/EmailCampaign";
import EmailCampaningList from "./components/Dashboard/email/EmailCampaningList";
import EmailValidator from "./components/Dashboard/email/EmailValidator";
import EmailContact from "./components/Dashboard/email/EmailContact";
import NotificationStatus from "./components/Dashboard/profile/NotificationStatus";
import DownloadCentre from "./components/Dashboard/download/DownloadCentre";
import MarketPlace from "./components/Dashboard/marketPlace/MarketPlace";
import NumberOptOutlist from "./components/Dashboard/sms/NumberOptOutlist";

//whatsapp List components
import WhatsappEnabale from "./components/Dashboard/whatsapp/WhatsappEnabale";
import WhatsappEmbed from "./components/Dashboard/whatsapp/WhatsappEmbed";
import WhatsappNumberList from "./components/Dashboard/whatsapp/WhatsappNumberList";
import WhatsappTemplateList from "./components/Dashboard/whatsapp/WhatsappTemplateList";
import WhatsAppSummaryUser from "./components/Dashboard/whatsapp/WhatsAppSummaryUser";

import SendWhstappMessage from "./components/Dashboard/whatsapp/SendWhstappMessage";
import WhatsAppTemplate from "./components/Dashboard/whatsapp/WhatsAppTemplate";
import WhatsAppOptinOtpout from "./components/Dashboard/whatsapp/WhatsAppOptinOtpout";
import WhatsAppCountReports from "./components/Dashboard/whatsapp/WhatsAppCountReports";
import WhatsappDetailReports from "./components/Dashboard/whatsapp/WhatsappDetailReports";
import WhatsAppGallery from "./components/Dashboard/whatsapp/WhatsAppGallery";
import WhatsappConversation from "./components/Dashboard/whatsapp/WhatsappConversation";
import AgentList from "./components/Dashboard/whatsapp/agent/AgentList";
import AddAgent from "./components/Dashboard/whatsapp/agent/AddAgent";
import HuntStrategy from "./components/Dashboard/whatsapp/agent/HuntStrategy";
import ChatSetting from "./components/Dashboard/whatsapp/chat/ChatSetting";
import ChatReport from "./components/Dashboard/whatsapp/chat/ChatReport";
import Trigger from "./components/Dashboard/whatsapp/chat/Trigger";
import TriggerEdit from "./components/Dashboard/whatsapp/chat/TriggerEdit";
import Notifications from "./components/Dashboard/whatsapp/chat/Notifications";
import Webhooks from "./components/Dashboard/whatsapp/chat/Webhooks";
import UserMgmt from "./components/Dashboard/whatsapp/chat/UserMgmt";
import AgentReport from "./components/Dashboard/whatsapp/chat/AgentReport";
import AgentEdit from "./components/Dashboard/whatsapp/chat/AgentEdit";
import ChatConversation from "./components/Dashboard/whatsapp/chat/ChatConversation";
import ShortCut from "./components/Dashboard/whatsapp/chat/ShortCut";
import WhatsappWidget from "./components/Dashboard/whatsapp/WhatsappWidget";

//Confing Components
import Protected from "./components/Utils/Protected";
// Admin Components
import AdminProtected from "./components/Utils/AdminProtected";
import Admin from "./components/Admin/Admin";
import UserManagement from "./components/Admin/UserManagement";
import AllUserManagement from "./components/Admin/AllUserManagement";
import UserDetails from "./components/Admin/UserDetails";
import UserAddPrice from "./components/Admin/UserAddPrice";
import UserPriceDetails from "./components/Admin/UserPriceDetails";
import UserTransactionDetails from "./components/Admin/UserTransactionDetails";
import AddEmployee from "./components/Admin/AddEmployee";
import TrafficManagement from "./components/Admin/TrafficManagement";
import EmployeeManagement from "./components/Admin/EmployeeManagement";
import AffiliateManagement from "./components/Admin/affiliate/AffiliateManagement";
import AffiliateCommision from "./components/Admin/affiliate/AffiliateCommision";
import AffiliatePayout from "./components/Admin/affiliate/AffiliatePayout";
import RouteManagement from "./components/Admin/RouteManagement";
import AddNewRoute from "./components/Admin/AddNewRoute";
import EditRoute from "./components/Admin/EditRoute";
import EditEmployee from "./components/Admin/EditEmployee";
import LoginHistory from "./components/Admin/LoginHistory";
import SignupHistory from "./components/Admin/SignupHistory";
import EntityHistory from "./components/Admin/EntityHistory";
import SernderHistory from "./components/Admin/SernderHistory";
import SmsTemplateHistory from "./components/Admin/SmsTemplateHistory";
import VoiceTemplateHistory from "./components/Admin/VoiceTemplateHistory";
import EmailTemplateHistory from "./components/Admin/EmailTemplateHistory";
import AwsSetupHistory from "./components/Admin/AwsSetupHistory";
import ApiRequest from "./components/Admin/ApiRequest";
import InvalidApiRequest from "./components/Admin/InvalidApiRequest";
import AddSpamWord from "./components/Admin/AddSpamWord";
import SpamReport from "./components/Admin/SpamReport";
import AddNewClient from "./components/Admin/AddNewClient";
import AddFaq from "./components/Admin/AddFaq";
import FaqList from "./components/Admin/FaqList";
import FeedBackMessage from "./components/Admin/FeedBackMessage";
import SupportTicket from "./components/Admin/SupportTicket";
import ViewTicket from "./components/Admin/ViewTicket";
import AdminTransactionHistory from "./components/Admin/TransactionHistory";
import ChangeRoute from "./components/Admin/ChangeRoute";
import ShiftRouter from "./components/Admin/ShiftRouter";
import ContactUsEnquiry from "./components/Admin/ContactUsEnquiry";
import ChangeCurrency from "./components/Admin/ChangeCurrency";
import AddBlackList from "./components/Admin/AddBlackList";
import AdminAnalytics from "./components/Admin/AdminAnalytics";
import AssignPlan from "./components/Admin/plan/AssignPlan";
import WhatsappProfile from "./components/Admin/whatsappManagement/WhatsappProfile";
import WhatsappSetting from "./components/Admin/whatsappManagement/WhatsappSetting";
import WhatsappSummary from "./components/Admin/whatsappManagement/WhatsappSummary";
import WhatsappSummaryApi from "./components/Admin/whatsappManagement/WhatsappSummaryApi";
import EmbededSignup from "./components/Admin/whatsappManagement/EmbededSignup";
import LcrRouteSetting from "./components/Admin/LcrRouteSetting";
import BillingSummary from "./components/Admin/BillingSummary";
import WhatsappSetup from "./components/Admin/whatsappManagement/WhatsappSetup";
import WhatsappSetupDetails from "./components/Admin/whatsappManagement/WhatsappSetupDetails";
import AdminMyProfile from "./components/Admin/AdminMyProfile";
import AdminChangePassword from "./components/Admin/AdminChangePass";
import UpdateWabaId from "./components/Admin/whatsappManagement/UpdateWabaId";
// rich Media admin component
import AddBlog from "./components/Admin/blog/AddBlog";
import BlogList from "./components/Admin/blog/BlogList";

// rich Media admin component
import RichMediaManagement from "./components/Admin/RichMediaManagement";
import RichMediaViewTemplate from "./components/Admin/RichMediaViewTemplate";

// Campaign component
import Campaign from "./components/Dashboard/campaign/WhataAppCampaign";
import SmsCampaign from "./components/Dashboard/campaign/sms/SmsCampaign";
import CampaignList from "./components/Dashboard/campaign/CampaignList";
import CampaignPreview from "./components/Dashboard/campaign/CampaignPreview";
import CreateContactList from "./components/Dashboard/contactList/CreateContactList";
import ContactList from "./components/Dashboard/contactList/ContactList";
import ViewContactList from "./components/Dashboard/contactList/ViewContactList";
import UploadContactList from "./components/Dashboard/contactList/UploadContactList";
import CampaignReport from "./components/Dashboard/campaign/CampaignReport";
import CampaignSummayReport from "./components/Dashboard/campaign/CampaignSummayReport";

// rcs
//admin
import  RcsAdminSetting  from "./components/Admin/rcs/RcsSetting";
import  RcsAdminSettingList  from "./components/Admin/rcs/RcsSettingList";
//outdated
import RcsApply from "./components/Dashboard/rcs/RcsApply";
import RcsApplyedList from "./components/Dashboard/rcs/RcsApplyedList";
import RcsTemplate from "./components/Dashboard/rcs/RcsTemplate";
import RcsReport from "./components/Dashboard/rcs/RcsReport";
import RcsDetailsReport from "./components/Dashboard/rcs/RcsDetailsReport";
import RcsAddAgent from "./components/Dashboard/rcs/RcsAddAgent";
import RcsAgentList from "./components/Dashboard/rcs/RcsAgentList";
import RcsPlan from "./components/Dashboard/rcs/RcsPlan";
import RcsSetting from "./components/Dashboard/rcs/RcsSetting";

import TestRichMedia from "./components/Dashboard/richMedia/TestRichMedia";
import RichMediaTemplateList from "./components/Dashboard/richMedia/RichMediaTemplateList";
import RichMediaTemplate from "./components/Dashboard/richMedia/TemplateRichMedia";
import RichMediaReport from "./components/Dashboard/richMedia/RichMediaReport";


import MyAccount from "./components/site/MyAccount";
import SignupSuccess from "./components/site/SignupSuccess";
import AgentForm from "./components/Dashboard/agent/AgentForm";
import AgentsLists from "./components/Dashboard/agent/AgentsLists";
import Automation from "./components/Dashboard/automation/Automation";
import AdminCampaignReport from "./components/Admin/AdminCampaignReport";
import WhatsappConvDownload from "./components/Dashboard/whatsapp/components/WhatsappConvDownload";
import { checkUserUrl } from "./components/Utils/Utils";
import otherfevicon from "./assets/favicon-defualt.png";
import WhatsappEnabaleNew from "./components/Dashboard/whatsapp/WhatsappEnableNew";
import EmbededSignupPinnacle from "./components/Dashboard/whatsapp/EmbededSignupPinnacle";
import PinnacleEmbededSignup from "./components/Admin/whatsappManagement/PinnacleEmbededSignup";
import PinnacleRegister from "./components/Admin/whatsappManagement/PinnacleRegister";
import PinnacleEmbededToken from "./components/Admin/whatsappManagement/PinnacleEmbededToken";
import SmsCampaignNew from "./components/Dashboard/campaign/sms/SmsCampaignNew";
import ResellerSetting from "./components/Setting/ResellerSetting";
import ReviewTemplate from "./components/Admin/ReviewTemplate";
import BulkSmsRouteSetting from "./components/Admin/BulkSmsRouteSetting";
import BlackList from "./components/Admin/BlackList";
import Optout from "./components/Dashboard/sms/Optout";
import AgentSetting from "./components/Admin/AgentSetting";
import WhatsAppTemplateAuthentication from "./components/Dashboard/whatsapp/WhatsappTemplateAuthentication";
import ProfileDetails from "./components/Admin/whatsappManagement/ProfileDetails";
import WhatsappTemplateSettingList from "./components/Admin/whatsappManagement/WhatsappTemplateSettingList";
import UpdateWhatsappToken from "./components/Admin/whatsappManagement/UpdateWhatsappToken";
import ChangeRouteSetting from "./components/Admin/whatsappManagement/ChangeRouteSetting";
import SmsCampaignReport from "./components/Admin/SmsCampaignReport";
import Settings from "./components/Dashboard/whatsapp/chat/Settings";
import Tag from "./components/Dashboard/whatsapp/chat/Tag";
import ResellerSignup from "./components/site/ResellerSignup";
import WhatsAppTemplateNew from "./components/Dashboard/whatsapp/WhatsappTemplateNew";
import CtaCampaignReport from "./components/Dashboard/campaign/CtaCampaignReport";
import EntityManagement from "./components/Admin/EntityMAnagement";
import QueueStatusWhatsapp from "./components/Admin/QueueStatusWhatsapp";
import AdminBilling from "./components/Admin/AdminBilling";
import FixedUrl from "./components/Dashboard/UrlShortner/FixedUrl";
import FixedUrlReport from "./components/Dashboard/ClickReport/FixedUrlReport";
import FixedUrlFilterReport from "./components/Dashboard/ClickReport/FixedUrlFilterReport";
function App() {
  const lStorage = JSON.parse(localStorage.getItem("login"));
  const url = document.location.href;
  let userId;
  if (lStorage) {
    userId = lStorage.user.userId;
  } else {
    userId = null;
  }
  useEffect(() => {
    if (checkUserUrl(url) === false) {
      const link =
        document.querySelector("link[rel='icon']") ||
        document.createElement("link");
      link.rel = "icon";
      link.href = otherfevicon;
      document.head.appendChild(link);
    }
  }, []);
  return (
    <div>
      <Router>
        <Switch>
          <Route path="/login" render={(props) => <Login {...props} />}></Route>
          <Route path="/signup-success" component={SignupSuccess} />
          <Route exact path="/">
            <Login />
          </Route>
          <Route
            path="/my-account"
            render={(props) => <MyAccount {...props} />}
          ></Route>
          <Route exact path="/signup">
            <Signup />
          </Route>
          <Route
            path="/resellersignup"
            render={(props) => <ResellerSignup {...props} />}
          ></Route>
          <Route path="/forgot-password">
            <ForgotPassword />
          </Route>
          {/* Dashboard Routes */}
          <StanmaxRoutes
            exact
            path="/stanmax/whatsappconv"
            component={StanmaxConv}
          />
          <Protected exact path="/dashboard" component={Dashboard} />

          {/* <Protected
            exact
            path="/dashboard/automation"
            component={Automation}
          /> */}
          <Protected
            exact
            path="/dashboard/create-project"
            component={CreateProject}
          />
          <Protected
            exact
            path="/dashboard/wpdemo"
            component={SendWhstappMessagepop}
          />
          <Protected
            exact
            path="/dashboard/create-event"
            component={CreateDirectEvent}
          />
          <Protected
            exact
            path="/dashboard/project-list"
            component={ProjectList}
          />
          <Protected exact path="/dashboard/event-list" component={EventList} />
          <Protected
            exact
            path="/dashboard/fallback-list"
            component={FallbackList}
          />
          <Protected
            exact
            path="/dashboard/create-agent"
            component={AgentForm}
          />
          <Protected
            exact
            path="/dashboard/agent-list"
            component={AgentsLists}
          />
          <Protected
            exact
            path="/dashboard/chat-report"
            component={ChatReport}
          />
          <Protected
            exact
            path="/dashboard/chat-setting/trigger"
            component={Trigger}
          />
          <Protected exact path="/dashboard/chat-setting/tag" component={Tag} />
          <Protected
            exact
            path="/dashboard/chat-setting/trigger/trigger-edit"
            component={TriggerEdit}
          />
          <Protected
            exact
            path="/dashboard/chat-setting"
            component={Settings}
          />
          <Protected
            exact
            path="/dashboard/notifications"
            component={Notifications}
          />
          <Protected
            exact
            path="/dashboard/chat-setting/webhooks"
            component={Webhooks}
          />

          <Protected
            exact
            path="/dashboard/user-management/:id"
            component={UserMgmt}
          />
          <Protected
            exact
            path="/dashboard/chat-report/agent-report/:id"
            component={AgentReport}
          />
          <Protected exact path="/dashboard/add-agent" component={AgentEdit} />
          <Protected
            exact
            path="/dashboard/agent/edit/:id"
            component={AgentEdit}
          />
          <Protected
            exact
            path="/dashboard/chat-conversation"
            component={ChatConversation}
          />

          <Protected exact path="/dashboard/shortcut" component={ShortCut} />

          <Protected
            exact
            path="/dashboard/opt-out-list"
            component={NumberOptOutlist}
          />

          <Protected
            exact
            path="/dashboard/sms-template"
            component={SmsTemplate}
          />
          <Protected
            exact
            path="/dashboard/voice-template"
            component={VoiceTemplate}
          />
          <Protected
            exact
            path="/dashboard/email-template"
            component={EmailTemplate}
          />
          <Protected
            exact
            path="/dashboard/sms-template-list"
            component={SmsTemplateList}
          />
          <Protected
            exact
            path="/dashboard/dlt-template-list"
            component={DltTemplateList}
          />
          <Protected
            exact
            path="/dashboard/voice-template-list"
            component={VoiceTemplateList}
          />
          <Protected
            exact
            path="/dashboard/email-template-list"
            component={EmailTemplateList}
          />
          <Protected
            exact
            path="/dashboard/details-reports"
            component={ReportsDetails}
          />
          <Protected exact path="/dashboard/reports" component={Reports} />

          <Protected exact path="/dashboard/my-profile" component={MyProfile} />
          <Protected
            exact
            path="/dashboard/change-password"
            component={ChangePassword}
          />
          <Protected exact path="/dashboard/aws-setup" component={AwsSetup} />
          <Protected
            exact
            path="/dashboard/create-sender-id"
            component={CreateSenderID}
          />
          <Protected
            exact
            path="/dashboard/add-entity-id"
            component={EntityID}
          />
          <Protected
            exact
            path="/dashboard/template-management"
            component={TemplateManagement}
          />
          <Protected
            exact
            path="/dashboard/aws-email-verify"
            component={AwsFromEmail}
          />
          <Protected
            exact
            path="/dashboard/transaction-payment"
            component={TransactionPayment}
          />
          <Protected
            exact
            path="/dashboard/transaction-history"
            component={TransactionHistory}
          />
          <Protected
            exact
            path="/dashboard/payment-success"
            component={PaymentSuccess}
          />
          <Protected
            exact
            path="/dashboard/payment-failed"
            component={PaymentFail}
          />
          <Protected
            exact
            path="/dashboard/price-list"
            component={BillingHistory}
          />
          <Protected exact path="/dashboard/support" component={Support} />
          <Protected
            exact
            path="/dashboard/create-new-ticket"
            component={CreateNewTickets}
          />
          <Protected
            exact
            path="/dashboard/configure-webhook"
            component={WebHook}
          />
          <Protected
            exact
            path="/dashboard/webhook-report"
            component={WebHookReport}
          />
          <Protected
            exact
            path="/dashboard/sms-price-list"
            component={SmsPrice}
          />
          <Protected
            exact
            path="/dashboard/sms-api-docs"
            component={SmsApiDashboard}
          />
          <Protected
            exact
            path="/dashboard/whatsapp-api-docs"
            component={WhatsAppAPIDashboard}
          />
          <Protected exact path="/dashboard/send-sms" component={SendSms} />
          <Protected
            exact
            path="/dashboard/sms-report"
            component={SmsReports}
          />
          <Protected exact path="/dashboard/optout" component={Optout} />
          <Protected
            exact
            path="/dashboard/email-report"
            component={EmailReports}
          />
          <Protected exact path="/dashboard/send-voice" component={SendVoice} />
          <Protected
            exact
            path="/dashboard/voice-api-docs"
            component={VoiceApiDashboard}
          />
          <Protected
            exact
            path="/dashboard/voice-price-list"
            component={VoicePriceList}
          />
          <Protected
            exact
            path="/dashboard/voice-report"
            component={VoiceReports}
          />
          <Protected exact path="/dashboard/send-email" component={SendEmail} />
          <Protected
            exact
            path="/dashboard/email-api-docs"
            component={EmailApiDashboard}
          />
          <Protected
            exact
            path="/dashboard/email-price"
            component={EmailPrice}
          />
          <Protected exact path="/dashboard/caller-id" component={CallerID} />
          <Protected
            exact
            path="/dashboard/api-docs"
            component={DashboardAPi}
          />
          <Protected
            exact
            path="/dashboard/email-campaning"
            component={EmailCampaign}
          />
          <Protected
            exact
            path="/dashboard/email-contact"
            component={EmailContact}
          />
          <Protected
            exact
            path="/dashboard/email-campaning-list"
            component={EmailCampaningList}
          />
          <Protected
            exact
            path="/dashboard/email-validator"
            component={EmailValidator}
          />
          <Protected
            exact
            path="/dashboard/notification"
            component={NotificationStatus}
          />
          <Protected
            exact
            path="/dashboard/download-centre"
            component={DownloadCentre}
          />
          <Protected
            exact
            path="/dashboard/market-place"
            component={MarketPlace}
          />
          {/* Campaign Rought */}

          <Protected
            exact
            path="/dashboard/campaign-start"
            component={Campaign}
          />
          <Protected
            exact
            path="/dashboard/sms-campaign-start"
            component={SmsCampaign}
          />
          <Protected
            exact
            path="/dashboard/sms-campaign"
            component={SmsCampaignNew}
          />

          <Protected
            exact
            path="/dashboard/create-contact-list"
            component={CreateContactList}
          />
          <Protected
            exact
            path="/dashboard/contact-list"
            component={ContactList}
          />
          <Protected
            exact
            path="/dashboard/fixed-url"
            component={FixedUrl}
          />
          <Protected
            exact
            path="/dashboard/fixed-url-report"
            component={FixedUrlReport}
          />
          <Protected
            exact
            path="/dashboard/fixed-url-report/:channel/:startdate/:enddate/:url"
            component={FixedUrlFilterReport}
          />
          <Protected
            exact
            path="/dashboard/campaign"
            component={CampaignList}
          />
          <Route
            path="/dashboard/campaign/view/:id"
            render={(props) => <CampaignPreview {...props} />}
          ></Route>
          <Route
            path="/dashboard/upload-contact-list/:id"
            render={(props) => <UploadContactList {...props} />}
          ></Route>
          <Route
            path="/dashboard/view-contact-list/:id/:list_type"
            render={(props) => <ViewContactList {...props} />}
          ></Route>
          <Route
            path="/dashboard/campaign/detailed-report/:channel/:id/:campaignName"
            render={(props) => <CampaignReport {...props} />}
          ></Route>
          <Route
            path="/dashboard/campaign/cta-report/:id"
            render={(props) => <CtaCampaignReport {...props} />}
          ></Route>
          <Route
            path="/dashboard/campaign/summary-report/:channel/:id/:campaignName"
            render={(props) => <CampaignSummayReport {...props} />}
          ></Route>

          {/* WhatsAPP Rought */}

          <Protected
            exact
            path="/dashboard/whatsapp-onboarding"
            component={WhatsappEnabale}
          />
          <Protected
            exact
            path="/dashboard/whatsapp-onboarding-new"
            component={WhatsappEnabaleNew}
          />
          <Protected
            exact
            path="/dashboard/whatsapp-onboarding/embedded"
            component={EmbededSignupPinnacle}
          />
          <Protected
            exact
            path="/dashboard/whatsapp-embed"
            component={WhatsappEmbed}
          />
          <Protected
            exact
            path="/dashboard/whatsapp"
            component={WhatsappNumberList}
          />
          <Protected
            exact
            path="/dashboard/whatsapp-summary"
            component={WhatsAppSummaryUser}
          />

          <Protected
            exact
            path="/dashboard/whatsapp-template-list"
            component={WhatsappTemplateList}
          />
          <Protected
            exact
            path="/dashboard/whatsapp-conversation"
            component={WhatsappConversation}
          />
          <Protected
            exact
            path="/dashboard/whatsapp-conversation/download"
            component={WhatsappConvDownload}
          />
          <Protected
            exact
            path="/dashboard/wp-agent-list"
            component={AgentList}
          />
          <Protected
            exact
            path="/dashboard/add-wp-agent"
            component={AddAgent}
          />
          <Protected
            exact
            path="/dashboard/agent-setting"
            component={HuntStrategy}
          />
          <Protected
            exact
            path="/dashboard/send-whatsapp"
            component={SendWhstappMessage}
          />
          <Protected
            exact
            path="/dashboard/create-whatsapp-template"
            component={WhatsAppTemplateNew}
          />
          <Protected
            exact
            path="/dashboard/create-whatsapp-template-new"
            component={WhatsAppTemplate}
          />
          <Protected
            exact
            path="/dashboard/create-whatsapp-template-authentication"
            component={WhatsAppTemplateAuthentication}
          />
          <Route
            path="/dashboard/preview-whatsapp-template/:id"
            render={(props) => <WhatsAppTemplate {...props} />}
          ></Route>
          <Protected
            exact
            path="/dashboard/whatsapp-optin-otpout"
            component={WhatsAppOptinOtpout}
          />
          <Protected
            exact
            path="/dashboard/whatsapp-report"
            component={WhatsAppCountReports}
          />
          <Protected
            exact
            path="/dashboard/whatsapp-gallery"
            component={WhatsAppGallery}
          />
          <Route
            path="/dashboard/whatsapp/edit/:id"
            render={(props) => <WhatsappEnabaleNew {...props} />}
          ></Route>
          <Route
            path="/dashboard/preview-whatsapp-autentication/:id"
            render={(props) => <WhatsAppTemplateAuthentication {...props} />}
          ></Route>
          <Route
            path="/dashboard/whatsapp-detail-reports/:dateFrom/:dateTo"
            render={(props) => <WhatsappDetailReports {...props} />}
          ></Route>

          <Protected
            exact
            path="/dashboard/whatsapp-widget"
            component={WhatsappWidget}
          />
          <Protected
            exact
            path="/dashboard/chat-setting/shortcuts"
            component={ChatSetting}
          />

          {/* rcs */}
          <AdminProtected
            exact
            path="/admin/rcs/rcs-setting-add"
            component={RcsAdminSetting}
          />
          <AdminProtected
            exact
            path="/admin/rcs/rcs-setting-list"
            component={RcsAdminSettingList}
          />
          {/* outdated */}
          <AdminProtected
            exact
            path="/dashboard/apply-rcs"
            component={RcsApply}
          />
          <AdminProtected
            exact
            path="/dashboard/rcs-list"
            component={RcsApplyedList}
          />
          <AdminProtected
            exact
            path="/dashboard/create-rcs-template"
            component={RcsTemplate}
          />
          <AdminProtected
            exact
            path="/dashboard/rcs-reports"
            component={RcsReport}
          />
          <AdminProtected
            exact
            path="/dashboard/rcs-details-reports"
            component={RcsDetailsReport}
          />
          <AdminProtected
            exact
            path="/admin/add-agent"
            component={RcsAddAgent}
          />
          <Route
            path="/dashboard/add-agent/edit/:id"
            render={(props) => <RcsAddAgent {...props} />}
          ></Route>
          <AdminProtected
            exact
            path="/dashboard/rcs-agent-list"
            component={RcsAgentList}
          />
          <AdminProtected
            exact
            path="/dashboard/rcs-pricing"
            component={RcsPlan}
          />
          <AdminProtected
            exact
            path="/dashboard/rcs-setting"
            component={RcsSetting}
          />
          <AdminProtected
            exact
            path="/admin/my-profile"
            component={AdminMyProfile}
          />
          <AdminProtected
            exact
            path="/admin/change-password"
            component={AdminChangePassword}
          />
          <AdminProtected
            exact
            path="/admin/billing"
            component={AdminBilling}
          />

          {/* Rich Media */}

          <Protected
            exact
            path="/dashboard/test-rich-media"
            component={TestRichMedia}
          />
          <Protected
            exact
            path="/dashboard/rich-media-template"
            component={RichMediaTemplateList}
          />
          <Protected
            exact
            path="/dashboard/rich-media-report"
            component={RichMediaReport}
          />
          <Protected
            exact
            path="/dashboard/create-rich-media-template"
            component={RichMediaTemplate}
          />

          <Route
            path="/dashboard/create-rich-media-template/edit/:id"
            render={(props) => <RichMediaTemplate {...props} />}
          ></Route>

          <Route
            path="/dashboard/email-campaning/edit/:id"
            render={(props) => <EmailCampaign {...props} />}
          ></Route>

          <Route
            path="/dashboard/template-management/edit/:id"
            render={(props) => <TemplateManagement {...props} />}
          ></Route>

          <Protected
            exact
            path="/dashboard/email-html-editor"
            component={EmailHTMLEditor}
          />

          {userId ? (
            <Route
              path="/dashboard/sms-template/edit/:id"
              render={(props) => <SmsTemplate {...props} />}
            />
          ) : (
            <Route
              path="/dashboard/sms-template/edit/:id"
              render={(props) => <Login {...props} />}
            ></Route>
          )}

          <Route
            path="/dashboard/sms-template/edit/:id"
            render={(props) => <SmsTemplate {...props} />}
          ></Route>

          <Route
            path="/dashboard/details-reports/:channel/:dateFrom/:dateTo"
            render={(props) => <ReportsDetails {...props} />}
          ></Route>

          <Route
            path="/dashboard/voice-detail-reports/:channel/:dateFrom/:dateTo"
            render={(props) => <VoiceDetailReports {...props} />}
          ></Route>

          <Route
            path="/dashboard/email-detail-reports/:channel/:dateFrom/:dateTo"
            render={(props) => <EmailDetailReports {...props} />}
          ></Route>

          <Route
            path="/dashboard/sms-detail-reports/:channel/:dateFrom/:dateTo"
            render={(props) => <SmsDetailReports {...props} />}
          ></Route>

          <Route
            path="/dashboard/voice-template/edit/:id"
            render={(props) => <VoiceTemplate {...props} />}
          ></Route>
          <Route
            path="/dashboard/email-template/edit/:id"
            render={(props) => <EmailTemplate {...props} />}
          ></Route>

          <Route
            path="/dashboard/event-list/:id"
            render={(props) => <ProjectEventList {...props} />}
          ></Route>

          <Route
            path="/dashboard/create-new-event/:id"
            render={(props) => <CreateEvent {...props} />}
          ></Route>

          <Route
            path="/dashboard/create-new-fallback"
            render={(props) => <CreateFallBack {...props} />}
          ></Route>

          <Route
            path="/admin/user-entity-pass/:id"
            render={(props) => <EntityByPass {...props} />}
          />

          <Protected exact path="/logout" component={Logout} />

          {/* 
              <Route path="/dashboard/ticket/:id/:tiketid/:status" 
                render={props=>(<SupportHistory {...props}/>)}></Route> */}

          {userId ? (
            <Route
              path="/dashboard/ticket/:id/:tiketid/:status"
              render={(props) => <SupportHistory {...props} />}
            />
          ) : (
            <Route
              path="/dashboard/ticket/:id/:tiketid/:status"
              render={(props) => <Login {...props} />}
            ></Route>
          )}

          {/* Admin Routes */}
          <AdminProtected exact path="/admin" component={Admin} />
          {/*user Account Components*/}

          <AdminProtected
            exact
            path="/admin/user-management"
            component={UserManagement}
          />
          <AdminProtected
            exact
            path="/admin/all-user-management"
            component={AllUserManagement}
          />

          <Route
            path="/admin/user-details/:id"
            render={(props) => <UserDetails {...props} />}
          />
          <Route
            path="/admin/user-price-details/:id"
            render={(props) => <UserPriceDetails {...props} />}
          />
          <Route
            path="/admin/user-add-price/:id/"
            render={(props) => <UserAddPrice {...props} />}
          />
          <Route
            path="/admin/change-currency/:id/"
            render={(props) => <ChangeCurrency {...props} />}
          />
          <Route
            path="/admin/bulk-sms-route/:id/"
            render={(props) => <BulkSmsRouteSetting {...props} />}
          />
          <Route
            path="/admin/user-transaction-details/:id"
            render={(props) => <UserTransactionDetails {...props} />}
          />
          <Route
            path="/admin/edit-employee/:id"
            render={(props) => <EditEmployee {...props} />}
          />
          <Route
            path="/admin/agent-setting/:id/"
            render={(props) => <AgentSetting {...props} />}
          />
          <AdminProtected
            exact
            path="/admin/add-employee"
            component={AddEmployee}
          />
          <AdminProtected
            exact
            path="/admin/traffic-management"
            component={TrafficManagement}
          />
          <AdminProtected
            exact
            path="/admin/employee-management"
            component={EmployeeManagement}
          />
          <AdminProtected
            exact
            path="/admin/affiliate-management"
            component={AffiliateManagement}
          />
          <AdminProtected
            exact
            path="/admin/affilite-commision-hostory"
            component={AffiliateCommision}
          />

          <Route
            path="/admin/affiliate-payout/:id/:username/:amount"
            render={(props) => <AffiliatePayout {...props} />}
          ></Route>

          <Route
            path="/admin/affilite-commision-hostory/:id"
            render={(props) => <AffiliateCommision {...props} />}
          ></Route>

          <AdminProtected
            exact
            path="/admin/route-management"
            component={RouteManagement}
          />
          <AdminProtected
            exact
            path="/admin/add-new-route"
            component={AddNewRoute}
          />
          <AdminProtected
            exact
            path="/admin/login-history"
            component={LoginHistory}
          />
          <AdminProtected
            exact
            path="/admin/signup-history"
            component={SignupHistory}
          />
          <AdminProtected
            exact
            path="/admin/entity-history"
            component={EntityHistory}
          />
          <AdminProtected
            exact
            path="/admin/sernder-history"
            component={SernderHistory}
          />
          <AdminProtected
            exact
            path="/admin/sms-template-history"
            component={SmsTemplateHistory}
          />
          <AdminProtected
            exact
            path="/admin/spam-word"
            component={AddSpamWord}
          />
          <AdminProtected
            exact
            path="/admin/spam-report"
            component={SpamReport}
          />
          <AdminProtected
            exact
            path="/admin/api-request"
            component={ApiRequest}
          />
          <AdminProtected
            exact
            path="/admin/invalid-api-request"
            component={InvalidApiRequest}
          />
          <AdminProtected
            exact
            path="/admin/voice-template-history"
            component={VoiceTemplateHistory}
          />
          <AdminProtected
            exact
            path="/admin/email-template-history"
            component={EmailTemplateHistory}
          />
          <AdminProtected
            exact
            path="/admin/aws-setup-history"
            component={AwsSetupHistory}
          />
          <AdminProtected exact path="/admin/add-faq" component={AddFaq} />
          <AdminProtected exact path="/admin/faq-list" component={FaqList} />
          <AdminProtected
            exact
            path="/admin/feedback"
            component={FeedBackMessage}
          />
          <AdminProtected
            exact
            path="/admin/whatsapp-account-list"
            component={WhatsappProfile}
          />
          {/* <AdminProtected
            exact
            path="/admin/whatsapp-summary"
            component={WhatsappSummary}
          /> */}
          <AdminProtected
            exact
            path="/admin/campaign-report"
            component={AdminCampaignReport}
          />
          <AdminProtected
            exact
            path="/admin/whatsapp-billing-summary"
            component={BillingSummary}
          />
          <AdminProtected
            exact
            path="/admin/review-template"
            component={ReviewTemplate}
          />
          <AdminProtected
            exact
            path="/admin/queue-whatsapp-status"
            component={QueueStatusWhatsapp}
          />
          <AdminProtected
            exact
            path="/admin/whatsapp-setup"
            component={WhatsappSetup}
          />
          <AdminProtected
            exact
            path="/admin/pinnacle-embeded-signup"
            component={PinnacleEmbededSignup}
          />
          <AdminProtected
            exact
            path="/admin/pinnacle-embeded-signup/register/:id/:wabaid/:pnid"
            component={PinnacleRegister}
          />
          <AdminProtected
            exact
            path="/admin/pinnacle-embeded-token"
            component={PinnacleEmbededToken}
          />
          <AdminProtected
            exact
            path="/admin/whatsapp-setup/details/:id"
            component={WhatsappSetupDetails}
          />
          <AdminProtected
            exact
            path="/admin/whatsapp-api"
            component={WhatsappSummaryApi}
          />
          <AdminProtected
            exact
            path="/admin/embeded-signup"
            component={EmbededSignup}
          />
          <AdminProtected
            path="/admin/whatsapp-account-setting/:id"
            render={(props) => <WhatsappSetting {...props} />}
          />
          <AdminProtected
            exact
            path="/admin/add-new-client"
            component={AddNewClient}
          />
          <AdminProtected
            exact
            path="/admin/support"
            component={SupportTicket}
          />
          <Route
            path="/admin/whatsapp-account-profile-details/:id"
            render={(props) => <ProfileDetails {...props} />}
          ></Route>
          <Route
            path="/admin/whatsapp-account-whatsapp-template-list/:id"
            render={(props) => <WhatsappTemplateSettingList {...props} />}
          ></Route>
          <Route
            path="/admin/whatsapp-account-update-whatsapp-token/:id"
            render={(props) => <UpdateWhatsappToken {...props} />}
          ></Route>
          <Route
            path="/admin/whatsapp-account-change-route/:id"
            render={(props) => <ChangeRouteSetting {...props} />}
          ></Route>
          <Route
            path="/admin/update-waba-id/:id"
            render={(props) => <UpdateWabaId {...props} />}
          ></Route>

          <AdminProtected
            exact
            path="/admin/blacklist-new"
            component={BlackList}
          />
          <AdminProtected
            exact
            path="/admin/sms-campaign-report"
            component={SmsCampaignReport}
          />
          <AdminProtected
            exact
            path="/admin/entity-management"
            component={EntityManagement}
          />
          <AdminProtected
            exact
            path="/admin/change-user-route"
            component={ChangeRoute}
          />
          <AdminProtected
            exact
            path="/admin/shift-router"
            component={ShiftRouter}
          />
          <AdminProtected
            exact
            path="/admin/lcr-route-setting"
            component={LcrRouteSetting}
          />

          <AdminProtected
            exact
            path="/admin/contact-us-enquiry"
            component={ContactUsEnquiry}
          />

          <AdminProtected
            exact
            path="/admin/transaction-history"
            component={AdminTransactionHistory}
          />

          <AdminProtected
            exact
            path="/admin/rich-media"
            component={RichMediaManagement}
          />
          <AdminProtected
            exact
            path="/admin/blacklist"
            component={AddBlackList}
          />
          <AdminProtected
            exact
            path="/admin/analytics"
            component={AdminAnalytics}
          />
          <AdminProtected
            exact
            path="/admin/reseller-setting"
            component={ResellerSetting}
          />
          <AdminProtected
            exact
            path="/admin/assign-plan"
            component={AssignPlan}
          />

          <AdminProtected exact path="/admin/add-blog" component={AddBlog} />

          <Route
            path="/admin/edit-blog/:id"
            render={(props) => <AddBlog {...props} />}
          ></Route>

          <AdminProtected exact path="/admin/blog-list" component={BlogList} />

          <Route
            path="/admin/rich-media-view-template/:id"
            render={(props) => <RichMediaViewTemplate {...props} />}
          ></Route>

          <Route
            path="/admin/edit-route/:id"
            render={(props) => <EditRoute {...props} />}
          ></Route>

          <Route
            path="/admin/support/edit/:id/:ticketnumber/:replyuserid"
            render={(props) => <ViewTicket {...props} />}
          ></Route>

          <Route
            path="/admin/add-faq/edit/:id"
            render={(props) => <AddFaq {...props} />}
          ></Route>

          <Route
            path="/admin/api-request/:username"
            render={(props) => <ApiRequest {...props} />}
          ></Route>
          <AdminProtected exact path="/admin/logout" component={Logout} />
          <Route exact component={Error}></Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
