import React, { useEffect, useState } from 'react'
import DashboardLeftMenu from '../DashboardLeftMenu'
import DashboardHeader from '../../Header/DashboardHeader'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { NODE_HOST_URL } from '../api/Api'
import EmptyImg from "../../Dashboard/assets/img/create-project.png";
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const FixedUrlFilterReport = () => {
    const lStorage = JSON.parse(localStorage.getItem("login"));
    const userId = lStorage.user.userId;
    const userToken = lStorage.token;
    const { channel, startdate, enddate, url } = useParams();
    const [data, setData] = useState([])
    const [search, setSearch] = useState('')

    

    useEffect(() => {
        dataList();
    }, [])

    const dataList = async () => {
        try {
            let response = await fetch(`${NODE_HOST_URL}/url_fixed_track?user_id=${userId}&method=fixed_url_detail&token=${userToken}&channel=${channel}&fromdate=${startdate}&todate=${enddate}&short_url=${url}`);
            let result = await response.json();
            if (result.success) {
                setData(result.data);
            }
        } catch (error) {
            console.log(error);
        }
    }


    return (
        <div className="wrapper">
            <DashboardLeftMenu />
            <div className="main">
                <DashboardHeader />
                <main className="content">
                    <div className="card">
                        <div className="card-body">
                            <div className="container-fluid p-0">
                                <div className="">
                                    <div className="p-2">
                                        <div className='row'>
                                            <div className='col-6'></div>
                                            <div className='col-6'>
                                                <div className="input-group mb-3">
                                                    <div className="input-group-prepend float-right">
                                                        <span className="input-group-text"><FontAwesomeIcon icon={faSearch} /></span>
                                                    </div>
                                                    <input
                                                        type="text"
                                                        id="searchInput"
                                                        name="searchdata"
                                                        value={search}
                                                        onChange={(e) => setSearch(e.target.value)}
                                                        className="form-control float-right"
                                                        placeholder="Search"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex-fill">
                                            {data.length > 0 ?
                                                <div className="table-responsive">
                                                    <table className="table table-striped table-sm">
                                                        <thead>
                                                            <tr>
                                                                <th className=" d-xl-table-cell"> Url </th>
                                                                <th className=" d-md-table-cell">IP</th>
                                                                <th className=" d-md-table-cell">Location</th>
                                                                <th className=" d-xl-table-cell"> Device </th>
                                                                <th className=" d-xl-table-cell">Created On </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                data.filter((x) => {
                                                                    return search.toLowerCase() === '' ? x : x.short_url.toLowerCase().includes(search) ||
                                                                      x.ip.toLowerCase().includes(search) ||
                                                                      x.url_device.toLowerCase().includes(search);
                                                                  }).map((item, i) =>
                                                                    <tr key={i}>
                                                                        <td className=" d-xl-table-cell">{item.short_url}</td>
                                                                        <td className=" d-xl-table-cell">{item.ip}</td>
                                                                        <td className=" d-xl-table-cell"> </td>
                                                                        <td className=" d-xl-table-cell">{item.url_device}</td>
                                                                        <td className=" d-md-table-cell">{item.created}</td>
                                                                    </tr>
                                                                )}
                                                        </tbody>
                                                    </table> </div> :
                                                <div className="text-center">
                                                    <div className="card-body">
                                                        <img alt="Empty Data" className="project-empty-img" src={EmptyImg} />
                                                        <p>No Entity ID Yet</p>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    )
}

export default FixedUrlFilterReport
